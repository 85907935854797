.free-profile {
  margin-top: 48px;
  margin-bottom: 50px;
  @include breakpoints(medium) {
    margin: 25px 0;
  }
  h2 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 1.17px;
    margin-bottom: 28px;
    @include breakpoints(small) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  .submission {
    display: flex;
    max-width: 376px;
    justify-content: center;
    margin: 20px auto;
    a {
      color: map-get($colors, white1);
      margin-right: 5px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: -20px;
        top: 8px;
        border: solid map-get($colors, white1);
        border-width: 0 3px 3px 0;
        padding: 4px;
        transform: rotate(-45deg);
      }
    }
    @include breakpoints('small') {
      width: 80%;
    }
  }
  .questions {
    font-size: 20px;
    font-weight: 500;
    color: #606060;
    letter-spacing: 0.83px;
  }
  .question-contactus {
    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.95;
      letter-spacing: 0.48px;
      margin: auto;
      display: flex;
      justify-content: center;
    }
    cursor: pointer;
  }
  .mail-icon-blue {
    position: relative;
    margin: 10px 5px 0 0;
  }
}
.video-howorks {
  width: 100%;
}
