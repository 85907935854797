.search-wrap {
  .search-result-heading-wrap {
    @include wrap-flx;
    margin: 25px 0 40px;
    align-items: center;
    @include breakpoints(small) {
      margin: 20px 0;
      justify-content: space-between;
      h1 {
        width: 180px;
      }
    }
    .filter-tab {
      @include filter-tab;
    }
  }
  .endorse-link-patient {
    display: none;
  }
  .scheduling-box {
    display: none;
  }
}

.docs-map-wrapper {
  width: 35%;
}

.no-result-modal-wrap .limit {
  font-size: 14px;
  font-weight: 400;
  color: #a7a9ac;
  letter-spacing: 0.6px;
}
.search-result-box {
  @include search-doc-cat-box;
  @include box-shadow(0 4px 14px 0 #e4eef3);
  background-color: map-get($colors, white1);
  padding: 10px 5px 20px 25px;
  margin-bottom: 30px;
  @include breakpoints(small) {
    padding: 30px 10px 20px;
    .endorse-link {
      margin-top: 25px;
    }
  }
}

.search-result-border {
  border: 2px solid #077db4;
}
.sponsor-border {
  @include sponsor-border;
}
.top-doc-border {
  @include top-doc-border;
}
.sponsor-doc-icon {
  @include sponsor-doc-icon;
}
.top-doc-icon {
  @include top-doc-icon;
}
.doctor-description-wrap--modified {
  display: flex;
}

.doctor-description-right--modified {
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 12px;
    color: #077db4;
    span {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.doctor-img--modified {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    max-width: 70px;
  }
}

.doctor-description-wrap {
  padding: 0px 0 10px;
  @include breakpoints(medium) {
    max-width: 475px;
    margin: 0 auto;
  }
  @include breakpoints(small) {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .doctor-img {
    margin: 0 50px 0 0;
    width: 121px;
    height: 121px;
    overflow: hidden;
    @include breakpoints(large) {
      margin-right: 10px;
    }
    @include breakpoints(small) {
      max-width: 100%;
      margin: 0 auto 10px;
    }
    @include border-radius(100%);
    img {
      width: 100%;
    }
    &.blue-border {
      @include border-blue;
    }
  }
  .doctor-description-right {
    width: 65%;
    @include breakpoints(small) {
      text-align: center;
      margin: 0 auto;
      width: 100%;
    }
  }
  h5 {
    color: map-get($colors, primary-main);
    a {
      position: relative;
      &:after {
        @include sprite;
        background-size: 520px;
        background-position: -280px -181px;
        width: 24px;
        height: 24px;
        left: unset;
        // right: -30px;
        top: unset;
        margin-left: 10px;
        // @include search-icons;
        // background-position: -16px -128px;
        // width: 24px;
        // height: 24px;
      }
    }
  }
  .doc-designation {
    @include doc-designation;
  }

  .endorsements,
  .rating-result {
    @include endorsements;
  }
  .rating-result {
    span {
      @include breakpoints(small) {
        &:last-child {
          display: block;
        }
      }
    }
  }
  .endorsements {
    @include endorsements-icon;
  }
  .rating-result {
    padding: 0;
    margin: 8px 0 0 0;
    .rating {
      margin: 0 auto;
    }
  }
}
.patient-features {
  @include patient-features;
  padding-top: 70px;
  @include breakpoints(medium) {
    padding-top: 0px;
  }
  @include breakpoints(small) {
    padding-top: 0;
  }
}
.scheduling-features {
  @include scheduling-features;
  padding-top: 40px;
  @include breakpoints(medium) {
    padding-top: 0px;
  }
  @include breakpoints(small) {
    padding-top: 0;
  }
  em {
    width: 85%;
    display: flex;
    margin: 0 auto;
  }
}
.request-appoint {
  text-align: center;
  margin: 5% 0 3%;
  .next-btn-pro {
    margin: 0;
    width: 355px;
    padding: 11px 0;
    @include breakpoints(small) {
      font-size: 14px;
    }
  }
  .next-btn-pro {
    @media screen and (max-width: 1140px) {
      width: 290px;
    }
  }
  &.show {
    display: block;
  }
  @include breakpoints(medium) {
    margin: 0 0 10px 0;
  }
}
.profile-mid-border,
.profile-mid-border-add {
  border-bottom: 2px solid map-get($colors, profile-border);
  margin-bottom: 24px;
  padding-bottom: 10px;
  @include breakpoints(small) {
    margin-bottom: 0px;
    padding-bottom: 20px;
  }
}
.profile-mid-border-add {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  @include breakpoints(medium) {
    margin-bottom: 24px;
    padding-bottom: 40px;
  }
  @include breakpoints(small) {
    padding-bottom: 25px;
  }
}
.profile-mid-border-address,
.profile-mid-border-address1 {
  border-top: 2px solid map-get($colors, profile-border);
  margin-top: 30px;
  padding-top: 20px;
  @include breakpoints(small) {
    margin-top: 20px;
    padding-top: 0px;
  }
}
.profile-mid-border-address1 {
  margin: 0;
  padding: 0;
  border: 0;
  @include breakpoints(medium) {
    border-top: 2px solid map-get($colors, profile-border);
    margin-top: 20px;
    padding-top: 20px;
  }
}
.result-address-wrap {
  @include wrap-flx;
  color: map-get($colors, black1);
  letter-spacing: 0.6px;
  line-height: 1.7;
  @include breakpoints(medium) {
    justify-content: space-between;
    margin-top: 20px;
  }
  .address-left {
    margin-right: 15px;
    width: 320px;
    @include reg-font;
    @include breakpoints(large) {
      margin-right: 30px;
      width: 250px;
    }
    @include breakpoints(medium) {
      width: 380px;
    }
    @include breakpoints(small) {
      margin-right: 0;
      width: 100%;
      order: 2;
    }
    span {
      display: block;
      position: relative;
      padding-left: 40px;
      margin-bottom: 16px;
      @include breakpoints(small) {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .see-location-link {
      @include wrap-flx;
      justify-content: flex-start;
      margin-left: 40px;
      @include breakpoints(small) {
        justify-content: flex-start;
        margin-left: 0;
      }
      a {
        border: 1px solid map-get($colors, filter-border);
        @include border-radius(3px);
        padding: 2px 23px;
        color: map-get($colors, black1);
        &:hover {
          background-color: map-get($colors, border-grey);
        }
        @include breakpoints(small) {
          margin-left: 40px;
        }
      }
    }
  }
  .address-right-map {
    width: 200px;
    @include breakpoints(small) {
      width: 100%;
      order: 1;
      margin-bottom: 25px;
      img {
        width: 100%;
      }
    }
    .address-map-box {
      margin-top: 5px;
      position: relative;
      @include breakpoints(small) {
        #map {
          width: 100% !important;
          button {
            margin: 3px !important;
          }
        }
      }
      a {
        position: absolute;
        z-index: 1;
        top: 3px;
        left: 3px;
        background-color: map-get($colors, white1);
        color: map-get($colors, grey-dark);
        padding: 1px 8px 1px 24px;
        font-size: 12px;
        &:before {
          position: absolute;
          content: '';
          left: 4px;
          top: 4px;
          background-image: url('#{$imagePath}direction-icon.png');
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
.sponsor-doctor-list {
  .top-docimg,
  .other-docimg {
    display: none;
  }
}
.top-doctor-list {
  .sponsored-docimg,
  .other-docimg {
    display: none;
  }
}
.other-doctor-list {
  .sponsored-docimg,
  .top-docimg {
    display: none;
  }
}
.doctor-search-wrap {
  @media screen and (max-width: 1140px) {
    margin-left: 20px;
  }
}
.doctor-search-wrap {
  .scheduling-title-doctor-search,
  .scheduling-date-doctor-search {
    margin: 0px;
    .col-md-6 {
      flex: unset;
      max-width: unset;
    }
  }
  .scheduling-title-doctor-search {
    .title-area {
      .title {
        @include breakpoints(small) {
          padding: 0;
        }
      }
      .cal {
        @include breakpoints(small) {
          padding: 0;
        }
      }
    }
  }
  @include breakpoints(large) {
    .scheduling-title-doctor-search {
      margin: 0 0 0 40px;
    }
    .scheduling-date-doctor-search {
      margin: 0;
    }
  }
  @include breakpoints(medium) {
    .scheduling-title-doctor-search {
      margin: 0;
    }
    .scheduling-date-doctor-search {
      margin: 0;
    }
  }
  .scheduling-box {
    width: 100%;
    .scheduling-component-main {
      margin-left: 4px;
      @include breakpoints(large) {
        margin-left: 0;
      }
      .date-area {
        height: 157px;
        overflow: hidden;
        &.show-height1 {
          height: auto;
        }
        @include breakpoints(small) {
          height: 175px;
          .date-column {
            width: 18.4%;
            margin: 0 2px;
          }
        }
      }
    }
  }
  .all-filter-wrap {
    margin-bottom: 10px;
    justify-content: flex-start;
  }
}
// No result modal
.no-result-modal-wrap {
  width: 800px;
  @include breakpoints(large) {
    width: 100%;
  }
  .noresult-topline {
    font-size: 18px;
    color: map-get($colors, black1);
    letter-spacing: 1px;
    @include breakpoints(small) {
      font-size: 16px;
    }
  }
  .cus-radio > label {
    padding-left: 35px;
    width: 100%;
    box-sizing: border-box;
    color: map-get($colors, grey-dark);
    @include reg-font;
    letter-spacing: 0.6px;
  }
  .cus-radio > label:before {
    width: 24px;
    height: 24px;
    border-color: map-get($colors, primary-main);
    position: absolute;
    left: 0;
    top: 0;
  }
  .cus-radio > input:checked + label:after {
    top: 5px;
    left: 5px;
  }
  textarea.input-pro {
    @include reg-font;
    height: 100px;
  }
  .next-btn-pro {
    max-width: 100% !important;
    @include breakpoints(small) {
      margin: 0 !important;
    }
  }
}

.sticky-wrapper {
  position: relative;
  height: auto;
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.sticky-patient {
  position: sticky;
  top: 0;
  background-color: map-get($colors, white1);
  z-index: 10;
  margin-bottom: 10px;
  padding-bottom: 5px;
  padding-top: 10px;
}
.sticky-provider {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: map-get($colors, white1);
}

.appointment-submit {
  .contactus {
    h3 {
      font-weight: 400;
    }
  }
}

.loadMore {
  &:disabled {
    background-color: #dfe0e1 !important;
    color: #9f9f9f !important;
  }
}

.doc-designation--modified {
  font-weight: 600;
  font-size: 10px;
}

.address--modified {
  font-size: 10px;
  margin-top: 8px;
}

.filter-tab,
.visit-available,
.new-patient-search {
  width: unset !important;
}

.filter-tab {
  margin-right: 20px;
  @media screen and (max-width: 1140px) {
    a {
      line-height: 2;
    }
  }
}

.new-patient-search {
  padding-right: 0 !important;
  border-right: none !important;
  @media screen and (max-width: 1140px) {
    display: none;
  }
  .new-pat-inner {
    margin-left: 45px;
  }
  .slider {
    margin-left: -35px;
  }
}

.visit-available {
  padding-right: 10px !important;
  margin-left: 75px;
  @media screen and (max-width: 1140px) {
    display: none;
  }
}

.visit-available {
  .slider {
    margin-left: -40px !important;
  }
}

.schedule-shrinked {
  .date-column {
    width: 15% !important;
  }
}

.doc-actions {
  order: 2;
  display: flex;
  width: 100%;
  margin-left: 24px;
  @media screen and (max-width: 990px) {
    margin-left: 0;
    flex-direction: column;
    .endorse-link {
      max-width: 250px;
      margin: 0;
    }
    .result-address-wrap {
      margin-left: 20px;
    }
  }
}
