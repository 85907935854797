.bg-svg-icons,
%bg-svg-icons {
  background-image: url("https://www.docscheduler.com/assets/images/sprite.svg");
  background-repeat: no-repeat;
}
.right-icon {
  @extend %bg-svg-icons;
  height: 25px;
  width: 32px !important;
  margin-left: auto;
  margin-right: auto;
  background-position: -162px -569px;
  cursor: pointer;
}
.info-icon {
  @extend %bg-svg-icons;
  height: 24px !important;
  width: 24px !important;
  background-position: -243px -79px;
  display: inline-block;
  vertical-align: middle;
}
.plus-icon {
  @extend %bg-svg-icons;
  height: 22px !important;
  width: 22px !important;
  display: inline-block;
  vertical-align: middle;
  background-position: -229px -569px;
  cursor: pointer;
}
.edit-icon {
  margin-right: 10px;
}
.edit-icon,
%edit-icon {
  @extend %bg-svg-icons;
  height: 25px !important;
  width: 25px !important;
  display: inline-flex;
  text-align: right;
  margin-left: auto;
  vertical-align: middle;
  background-position: -495px -368px;
  cursor: pointer;
}

.edit-icon-position {
  @extend %edit-icon;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.edit-icon-position-top {
  @extend %edit-icon;
  right: 0;
  position: absolute;
  top: 2%;
  cursor: pointer;
}

.delete-icon {
  @extend %edit-icon;
  background-position: -496px -405px;
  cursor: pointer;
}

.payment-card-icon {
  @extend %bg-svg-icons;
  height: 40px !important;
  width: 258px !important;
  background-position: -6px -631px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.down-arrow-lg {
  @extend %bg-svg-icons;
  height: 20px !important;
  width: 18px !important;
  background-position: -286px -561px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.pass-hide-eye-icon {
  @extend %bg-svg-icons;
  height: 18px !important;
  width: 22px !important;
  background-position: -322px -566px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.pass-show-eye-icon {
  @extend %bg-svg-icons;
  height: 18px !important;
  width: 22px !important;
  background-position: -350px -566px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.svgtime-icon {
  @extend %bg-svg-icons;
  height: 23px !important;
  width: 23px !important;
  background-position: -384px -566px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.google-icon {
  @extend %bg-svg-icons;
  height: 23px !important;
  width: 23px !important;
  background-position: -417px -566px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.calender-icon {
  @extend %bg-svg-icons;
  height: 26px !important;
  width: 28px !important;
  background-position: -517px -133px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
  cursor: pointer;
}
