.home-wrap {
  padding: 0;
  .home-top-container {
    position: relative;
    padding: 60px 0 30px;
    @include breakpoints(medium) {
      padding: 20px 0 20px;
    }
    .home-logo {
      text-align: center;
      max-width: 700px;
      margin: 0 auto;
      @include breakpoints(medium) {
        width: 100%;
        img {
          width: 75%;
        }
      }
      span {
        font-size: 28px;
        font-weight: 500;
        color: map-get($colors, black1);
        text-align: center;
        display: block;
        margin: 20px 0 0;
        letter-spacing: 0.6px;
        @include breakpoints(medium) {
          font-size: 18px;
        }
        @include breakpoints(small) {
          font-size: 14px;
        }
      }
    }
    .homelogo-new {
      @include wrap-flx;
      justify-content: center;
      .logo-icon {
        width: 70px;
        @include breakpoints(small) {
          width: 34px;
        }
      }
      strong {
        color: map-get($colors, primary-main);
        margin-left: 5px;
        font-size: 72px;
        line-height: 70px;
        @include breakpoints(small) {
          margin-left: 3px;
          font-size: 34px;
          line-height: 33px;
        }
      }
    }
  }
  .home-top-fields {
    margin: 26px auto 0;
    width: 100%;
    padding-bottom: 15px;
    @include wrap-flx;
    justify-content: space-between;
    @include breakpoints(small) {
      margin-top: 20px;
    }
    span {
      position: relative;
      &:nth-child(1) {
        width: 37%;
        @include breakpoints(large) {
          width: 100%;
          margin-bottom: 20px;
        }
        @include breakpoints(small) {
          margin-bottom: 10px;
        }
        input {
          padding-left: 60px;
          @include breakpoints(small) {
            padding-left: 45px;
          }
        }
      }
      &:nth-child(2) {
        width: 21%;
        @include breakpoints(large) {
          width: 35%;
        }
        @include breakpoints(medium) {
          width: 33%;
        }
        @include breakpoints(small) {
          width: 100%;
          margin-bottom: 15px;
        }
        input[type='text'] {
          padding-left: 65px;

          @include breakpoints(small) {
            padding-left: 50px;
          }
        }
      }
      &:nth-child(3) {
        width: 25.5%;
        @include breakpoints(large) {
          width: 48%;
        }
        @include breakpoints(medium) {
          width: 41%;
        }
        @include breakpoints(small) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 12%;
        @include breakpoints(medium) {
          width: 23%;
        }
        @include breakpoints(small) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    &.searchFilter {
      span {
        position: relative;
        &:nth-child(1) {
          width: 42%;
          @include breakpoints(large) {
            width: 100%;
            margin-bottom: 20px;
          }
          @include breakpoints(small) {
            margin-bottom: 10px;
          }
          input {
            padding-left: 60px;

            @include breakpoints(small) {
              padding-left: 45px;
            }
          }
        }
        &:nth-child(2) {
          width: 23%;
          @include breakpoints(large) {
            width: 35%;
          }
          @include breakpoints(medium) {
            width: 33%;
          }
          @include breakpoints(small) {
            width: 48%;
          }
          input {
            padding-left: 65px;
            @include breakpoints(small) {
              padding-left: 50px;
            }
          }
        }
        &:nth-child(3) {
          width: 29%;
          @include breakpoints(large) {
            width: 48%;
          }
          @include breakpoints(medium) {
            width: 41%;
          }
          @include breakpoints(small) {
            width: 50%;
          }
        }
        &:nth-child(4) {
          width: 13%;
          @include breakpoints(medium) {
            width: 23%;
          }
          @include breakpoints(small) {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
    .find-home {
      @include find-home;
    }
    .input-home {
      @include input-home;
    }
    .search-home-btn {
      @include search-home;
      height: 63px;
    }
  }
  .home-bottom-field-txt {
    span {
      color: map-get($colors, black1);
      font-size: 13px;
      display: block;
      font-weight: 400;
      letter-spacing: 0.6px;
      &.f-wdth {
        width: 100%;
        @include breakpoints(small) {
          font-size: 11px;
          letter-spacing: 0px;
        }
      }
    }

    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .home-check {
    padding: 20px 0 0;
    margin: 0;
    @include breakpoints(small) {
      .cus-check {
        label {
          font-size: 12px;
        }
      }
    }
    .cus-check {
      label {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        &::before {
          border-radius: 4px;
        }
      }
    }
  }

  .top-doc-list-wrap {
    overflow-x: hidden;
    margin: 60px 0;
    .slick-list {
      overflow: unset;
    }
  }
  .specialty-doc-list-wrap {
    overflow-x: hidden;
    padding: 50px 0 0;
    h1 {
      @include breakpoints(small) {
        font-size: 20px;
        line-height: 1.35;
        letter-spacing: 0.48px;
        text-align: center;
        font-weight: 500;
      }
    }
    @include breakpoints(medium) {
      padding-top: 30px;
    }
    .spec-list-cat {
      display: flex;
      width: 100%;
    }

    a {
      flex: 0 0 calc(25% - 20px); /* Adjust the width as needed */
      box-sizing: border-box;
      position: relative;
      height: 0;
      margin-right: 10px;
      padding-bottom: calc(25% - 20px); /* Maintain a square aspect ratio */
      margin-left: 10px; /* Add margin to the left of all items except the first one */
      transition: transform 0.3s ease-in-out;
    }

    a:first-child {
      margin-left: 0; /* Remove margin for the first item */
    }

    a:last-child {
      margin-right: 0; /* Remove margin for the last item */
    }

    .img-container {
      border-radius: 4px;
      position: relative;
      height: 256px;
      width: 100%;
      cursor: pointer;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }

    .overlay-cat {
      cursor: pointer;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(33, 71, 96, 0.5);
    }

    .cat-overlay-text {
      width: 100%;
      cursor: pointer;
      position: absolute;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      color: #fff;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      .text {
        //styleName: Bold/24;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .arrow-control {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: #3498db;
      color: #fff;
      font-size: 18px;
      border: none;
      padding: 10px;
      cursor: pointer;
      transition: background 0.3s ease-in-out;
    }

    .arrow-control:disabled {
      background: #ccc;
      cursor: not-allowed;
    }

    .arrow-control.left {
      left: 10px;
    }

    .arrow-control.right {
      right: 10px;
    }
  }
}
.cateFindItem {
  padding: '0.25rem 1.25rem';
  font-size: 14px;
  border: 0px;
  border: transparent solid 1px;
}
.cateFindItem:hover {
  color: #077db4;
  border: #077db4 solid 1px;
  background: #fff;
  cursor: pointer;
}
.autoContainerCate {
  background-color: #fff;
  box-shadow: 0 2px 7px 0 #cedae1;
  overflow-y: scroll;
  max-height: 250px;
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 999999;
  @extend %scrollbarDesign3;
}
.cateSearch {
  padding: 10px 5px;
  font-size: 15px;
}
.autoContenrCate {
  background-color: #fff;
  margin-bottom: 5px;
}
.autoContainerInsuranceName {
  background-color: #fff;
  box-shadow: 0 2px 7px 0 #cedae1;
  overflow-y: scroll;
  max-height: 250px;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 999999;
  @extend %scrollbarDesign3;
}
.autoContentInsuranceName {
  background-color: #fff;
  margin-bottom: 5px;
}
.insuranceItem:hover {
  color: #077db4;
  border: #077db4 solid 1px;
  background: #fff;
  cursor: pointer;
}

.main-subheading {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #545454;
}

.slick-list {
  padding-left: 0 !important;
}

.slick-slide {
  // padding-left: 18px;
  padding-right: 18px;
}

.slick-list {
  margin-left: -18px;
  margin-right: -18px;
}

.slick-slide {
  // padding: 0 16px !important;
  box-sizing: border-box !important;
}

.slick-arrow {
  width: 25px;
  height: 25px;
  top: -30px;
}

.slick-next,
.slick-prev {
  &::before {
    content: '';
  }
}

.slick-next {
  right: 0%;
  background: url('../../Icons/chevron-right.svg') 0 0 / 100% no-repeat;
  &:hover {
    background: url('../../Icons/chevron-right.svg') 0 0 / 100% no-repeat;
  }
  &:focus {
    background: url('../../Icons/chevron-right.svg') 0 0 / 100% no-repeat;
  }
  &.slick-disabled {
    background: url('../../Icons/chevron-right-disabled.svg') 0 0 / 100%
      no-repeat;
  }
}

.slick-prev {
  right: 40px;
  left: unset;
  background: url('../../Icons/chevron-left.svg') 0 0 / 100% no-repeat;
  &:hover {
    background: url('../../Icons/chevron-left.svg') 0 0 / 100% no-repeat;
  }
  &:focus {
    background: url('../../Icons/chevron-left.svg') 0 0 / 100% no-repeat;
  }
  &.slick-disabled {
    background: url('../../Icons/chevron-left-disabled.svg') 0 0 / 100%
      no-repeat;
  }
}

.top-doctors-heading {
  @include breakpoints(small) {
    display: none;
  }
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    background-color: #077db4;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
  }
}

.tag {
  margin-left: 16px;
  padding: 8px 0;
  border-radius: 100px;
  span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.top-doctor {
  width: 256px;
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 4px 14px 0px #e4eef3;
  position: relative;
}

.top-doctor--header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.top-doctor--save {
  top: 16px;
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-left: 8px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #077db4;
  }
}

.top-doctor--img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 112px;
    height: 112px;
    object-fit: cover;
  }
}

.top-doctor--info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  span {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #077db4;
    margin-right: 4px;
  }
}

.top-doctor--title {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  span {
    //styleName: Medium/14;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.top-doctor--reviews {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    margin-bottom: 4px;
  }
  p {
    margin-top: 4px;
  }
  p,
  span {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.rating {
  height: 24px;
}

.top-doctor--button {
  display: block;
  margin-top: 4px;
  width: 100%;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #077db4;
  color: #fff;
  border: 1px solid transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  &:hover {
    background-color: #fff;
    border: 1px solid #077db4;
    color: #077db4;
  }
}

.content__container--mobile {
  display: none;
  @media screen and (max-width: 579px) {
    display: flex;
  }
}
.content-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .row-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .input-tag-wrapper {
      display: flex;
      z-index: 10;
    }
  }
}
.content__container {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  @media screen and (max-width: 579px) {
    display: none;
  }

  &__text {
    // display: inline;
    // float: left;
    // margin: 0;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
  }

  &__list {
    margin-left: 6px;
    margin-top: 300px;
    // padding-left: 110px;
    text-align: left;
    list-style: none;
    animation-name: change;
    animation-duration: 16s;
    animation-iteration-count: infinite;

    &__item {
      margin: 0;
      color: #077db4;
      font-family: Roboto;
      font-size: 28px;
      font-weight: 700;
      line-height: 33px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 8px;
    }
  }
}

.top-doctors-heading--mobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  .tags {
    margin-top: 16px;
    display: flex;
    .tag {
      margin-left: 0;
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }
}

@keyframes change {
  0%,
  8.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.66%,
  20.32% {
    transform: translate3d(0, -12.5%, 0);
  }
  24.32%,
  32.98% {
    transform: translate3d(0, -25%, 0);
  }
  38.98%,
  44.64% {
    transform: translate3d(0, -37.5%, 0);
  }
  50.64%,
  57.3% {
    transform: translate3d(0, -50%, 0);
  }
  62.3%,
  69.96% {
    transform: translate3d(0, -62.5%, 0);
  }
  74.3%,
  81.96% {
    transform: translate3d(0, -75%, 0);
  }
  88.3%,
  95.96% {
    transform: translate3d(0, -87.5%, 0);
  }
  98%,
  99.5% {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 767px) {
  .specialty-doc-list-wrap {
    h1 {
      font-size: 26px !important;
      width: 75%;
      text-align: left !important;
    }
  }
}
