@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Montserrat:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap');
body,
html {
  height: 100%;
  width: 100%;
}
body,
article,
aside,
details,
figcaption,
figure,
footer,
div,
header,
menu,
nav,
section,
ul,
h1,
h2,
h3,
h4,
h5,
ul,
li,
h6,
form {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
}
input,
button,
checkbox,
label {
  padding: 0;
  margin: 0;
}
body {
  font-family: 'Roboto', sans-serif;
  color: #000;
  overflow-x: hidden;
}
*:focus {
  outline: none;
}
a {
  text-decoration: none;
  border: 0;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
a:hover {
  text-decoration: none;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.clr {
  clear: both;
}
select::-ms-expand {
  display: none;
}
form {
  margin: 0;
  padding: 0;
}
img {
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
}
.scale {
  max-width: 100%;
  height: auto;
  width: auto\9;
}
h1 {
  letter-spacing: 0.6px;
}
.under-line {
  position: relative;
}
.under-line:after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 1px;
  width: 0%;
  background-color: #e67525;
  transition: all 0.2s ease-in-out;
}
.under-line:hover:after {
  width: 100%;
}
.marin-wrap {
  width: 100%;
  position: relative;
}

/** provider **/
.input-pro {
  border: 1px solid #dddddd;
  width: 100%;
  padding: 14px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.pro-label {
  @include pro-label;
}
.input-search {
  border: 1px solid #dddddd;
  width: 100%;
  font-size: 15px;
  color: #606060;
  padding: 15px 40px 15px 20px;
  @include breakpoints(medium) {
    padding: 10px 40px 10px 20px;
  }
}

.cus-check {
  padding: 0;
  margin: 0;
}
.cus-check > input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.cus-check > label {
  position: relative;
  cursor: pointer;
}
.cus-check > label:before {
  content: '';
  -webkit-appearance: none;
  background-color: #ffffff;
  border: 1px solid #ddd;
  height: 20px;
  top: -2px;
  width: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}
.cus-check > input:checked + label:before {
  background-color: #1680b8;
  border: 1px solid #1680b8;
}
.cus-check > input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.cus-check-rev {
  padding: 0;
  margin: 0;
}
.cus-check-rev > input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.cus-check-rev > label {
  position: relative;
  cursor: pointer;
  padding-left: 35px;
}
.cus-check-rev > label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ddd;
  height: 24px;
  top: 0px;
  width: 24px;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  left: 0;
}
.cus-check-rev > input:checked + label:before {
  background-color: #ffffff;
  border: 1px solid #1680b8;
}
.cus-check-rev > input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 9px;
  width: 7px;
  height: 14px;
  border: solid #1680b8;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.cus-radio {
  padding: 0;
  margin: 0;
}
.cus-radio > input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.cus-radio > label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #404040;
}
.cus-radio > label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ddd;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  top: 6px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 100%;
}
.cus-radio > input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #1680b8;
  top: 9px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.select {
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
}
.myform select {
  -webkit-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  border: none;
  padding: 15px 20px;
  background-image: url('#{$imagePath}download1.png');
  background-position: right 15px top 52%;
  background-repeat: no-repeat;
  background-size: 12px;
  border: 1px solid #dddddd;
  position: relative;
  width: 100%;
  background-color: map-get($colors, white1);
}
.myform select::-ms-expand {
  display: none;
}

.next-btn-pro {
  width: 100%;
  cursor: pointer;
  background-color: map-get($colors, primary-main);
  border: none;
  color: #fff;
  padding: 13px 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  margin-top: 25px;
  transition: all 0.3s ease-in-out;
  &:disabled {
    opacity: 0.5;
  }
}
.next-btn-pro:hover {
  background-color: map-get($colors, primary-main);
}

.next-btn-pro.danger {
  background-color: map-get($colors, danger);
}
.next-btn-pro.danger:hover {
  background-color: map-get($colors, danger);
}
.rev-btn-pro {
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: none;
  color: map-get($colors, primary-main);
  border: 1px solid map-get($colors, primary-main);
  padding: 14px 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 25px;
  transition: all 0.3s ease-in-out;
}
.rev-btn-pro:hover {
  background-color: #f3f3f3;
}

.del-btn-pro {
  width: 100%;
  cursor: pointer;
  background-color: map-get($colors, white1);
  border: none;
  color: map-get($colors, delete-color);
  border: 1px solid map-get($colors, delete-color);
  padding: 14px 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  margin-top: 25px;
  transition: all 0.3s ease-in-out;
}

.error-border {
  border-color: map-get($colors, error) !important;
}

.error-input {
  color: map-get($colors, error);
  font-size: 11px;
}
.error-input-stick {
  position: absolute;
  bottom: -1px;
  left: 15px;
  @include breakpoints(small) {
    position: relative;
    bottom: 0;
    left: 0;
  }
}

.error-block-cal {
  position: absolute;
  bottom: -20px;
  width: 205px;
  @include breakpoints(small) {
    bottom: -28px;
  }
}

@include breakpoints(small) {
  .pro-label {
    font-size: 14px;
  }
  .input-pro,
  .myform select,
  .cus-check-rev > label {
    font-size: 12px;
  }
}

.tick-icon {
  position: relative;
  &:before {
    @include sprite;
    background-position: -297px -30px;
    width: 24px;
    height: 24px;
  }
}
.video-icon {
  position: relative;
  &:before {
    @include sprite;
    background-position: -366px -76px;
    width: 28px;
    height: 24px;
  }
}
.map-icon {
  position: relative;
  &:before {
    @include sprite;
    background-position: -366px -132px;
    width: 24px;
    height: 26px;
  }
}
.red-cross-icon {
  position: relative;
  &:before {
    @include sprite;
    background-position: -446px -78px;
    width: 24px;
    height: 24px;
  }
}
.grey-cross-icon {
  position: relative;
  &:before {
    @include sprite;
    background-position: -446px -32px;
    width: 24px;
    height: 24px;
  }
}

.desig-icon {
  &:before {
    @include sprite;
    background-size: 430px;
    width: 35px;
    height: 28px;
    background-position: -96px -104px;
  }
}
.resi-icon {
  &:before {
    @include sprite;
    background-size: 430px;
    width: 36px;
    height: 34px;
    background-position: -397px -196px;
  }
}
.lang-icon {
  &:before {
    @include sprite;
    background-size: 550px;
    width: 30px;
    height: 33px;
    background-position: -67px -251px;
  }
}

.endorse-link {
  font-size: 18px;
  letter-spacing: 1px;
  max-width: 355px;
  margin: 0 auto;
  font-weight: 500;
  @include breakpoints(small) {
    font-size: 14px;
  }
  a,
  button {
    color: map-get($colors, link-endorse);
    display: block;
    border: 1px solid map-get($colors, link-endorse);
    text-align: center;
    @include border-radius(4px);
    padding: 14px 0;
    margin-bottom: 20px;
    line-height: normal;
    width: 100%;
    font-weight: 500;
    background-color: #ffff;
    &:hover {
      background-color: map-get($colors, light-grayish-blue);
    }
    &:disabled {
      background-color: #e4eef3;
      span {
        &:before {
          display: none;
        }
        padding-left: 0;
      }
    }
    span {
      position: relative;
      padding-left: 35px;
      &:first-child {
        &:before {
          @include sprite;
          top: -4px;
          background-position: -67px -427px;
          width: 27px;
          height: 24px;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.rating {
  height: 24px;
  font-size: 1px;
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  &.view {
    background-image: url('#{$imagePath}star-icons.png');
    background-repeat: repeat-x;
    background-position: 0 0;
    width: 140px;
    margin-right: 10px;
  }
  // margin-right: 5px;
  span {
    height: 24px;
    font-size: 1px;
    background-image: url('#{$imagePath}star-icons.png');
    background-repeat: repeat-x;
    display: block;
    background-position: 0 -24px;
  }
}
.rating0 {
  width: 0%;
}
.rating00 {
  width: 0%;
}
.rating05 {
  width: 10%;
}
.rating1 {
  width: 20%;
}
.rating10 {
  width: 20%;
}
.rating15 {
  width: 30%;
}
.rating2 {
  width: 40%;
}
.rating20 {
  width: 40%;
}
.rating25 {
  width: 50%;
}
.rating3 {
  width: 60%;
}
.rating30 {
  width: 60%;
}
.rating35 {
  width: 70%;
}
.rating4 {
  width: 80%;
}
.rating40 {
  width: 80%;
}
.rating45 {
  width: 90%;
}
.rating5 {
  width: 100%;
}
.rating50 {
  width: 100%;
}

.add-link {
  @include medium-font;
  @include breakpoints(small) {
    font-size: 13px;
  }
  a {
    color: map-get($colors, primary-main);
    &:hover {
      text-decoration: underline;
    }
  }
}

// right mark

.right-mark {
  width: 70px;
  height: 70px;
  position: relative;
  background-color: map-get($colors, right-tick);
  margin: 0 auto;
  @include border-radius(50%);
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 16px;
    height: 37px;
    border: solid #ffffff;
    border-width: 0 4px 4px 0;
    @include transform(rotate(48deg));
  }
}

.pass-rules {
  font-size: 14px;
  line-height: 1.6;
  .head-rules {
    font-weight: 900;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: disc;
      list-style-position: inside;
      color: map-get($colors, grey-dark);
    }
  }
  &.validation-rules {
    font-size: 11px;
    margin: 10px 0 5px;
    color: #f91717;
    .head-rules {
      font-weight: 400;
    }
    ul {
      li {
        color: #f91717;
      }
    }
  }
}

.green-right {
  width: 32px;
  height: 25px;
  position: relative;
  margin: 0 auto;
  @include border-radius(50%);
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 16px;
    height: 37px;
    border: solid map-get($colors, right-tick);
    border-width: 0 4px 4px 0;
    @include transform(rotate(48deg));
  }
}
// switch css
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  // height: 12px;
  input {
    display: none;
    &:checked + .slider {
      background-color: #a6daf2;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c9c9c9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    height: 15px;
    width: 42px;
    &:before {
      position: absolute;
      content: '';
      height: 23px;
      width: 23px;
      left: -5px;
      bottom: -3px;
      background-color: #989898;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
    &::after {
      content: 'No';
      color: #000;
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: -20px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  input:checked + .slider:before {
    transform: translateX(30px);
    background-color: #077db4;
  }
  input:checked + .slider:after {
    content: 'Yes';
  }
}

.save-doc-bookmark {
  color: map-get($colors, primary-main);
  @extend %save-doc-font;
  .icon-docsave {
    width: 16px;
    height: 22px;
    position: relative;
    margin: 0 10px 0 0;
    top: 2px;
  }
}

.doc-save {
  position: absolute;
  top: 0;
  left: 0;
  color: map-get($colors, primary-main);
  @extend %save-doc-font;
  display: none;
  &.profile {
    top: 15px;
    left: 15px;
  }
  .icon-docsave {
    width: 16px;
    height: 22px;
    position: relative;
    margin: 0 10px 0 0;
    top: -2px;
  }
}
.doc-unsave {
  position: absolute;
  top: 0;
  left: 0;
  color: map-get($colors, primary-main);
  @extend %save-doc-font;
  display: none;
  &.profile {
    display: flex;
    align-items: center;
    top: 15px;
    left: 15px;
  }
  .icon-docsave {
    width: 16px;
    height: 22px;
    position: relative;
    margin: 0 10px 0 0;
    top: -2px;
  }
}
.grade-a,
.grade-b,
.grade-c,
.grade-d,
.grade-e,
.grade-f {
  padding: 7px 40px;
  text-align: center;
  @include border-radius(4px);
  color: map-get($colors, white1);
  cursor: pointer;
  max-width: 89px;
  margin: 0px;
}
.grade-a {
  background-color: map-get($colors, primary-main);
}
.grade-b {
  background-color: map-get($colors, grade-b);
}
.grade-c {
  background-color: map-get($colors, grade-c);
}
.grade-d {
  background-color: map-get($colors, grade-d);
}
.grade-e {
  background-color: map-get($colors, grade-e);
}
.grade-f {
  background-color: map-get($colors, grade-f);
}

.hide-for-desktop {
  display: none;
  @include breakpoints(medium) {
    display: unset;
  }
}

.hide-for-tablet {
  @include breakpoints(medium) {
    display: none;
  }
  @include breakpoints(small) {
    display: unset;
  }
}

.hide-for-mobile {
  @include breakpoints(small) {
    display: none;
  }
}
