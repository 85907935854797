.chat-wrapper {
  position: absolute;
  overflow-y: hidden;
  bottom: 80px;
  right: 20px;
  width: 350px;
  height: 400px;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @include breakpoints(small) {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.chat-content--wrapper {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 10px;
}

.chat-questions,
.chat-content {
  .active {
    background-color: #077db4;
    color: white;
    &:hover {
      background-color: #f8f9fa;
    }
  }
}
.chat-content {
  margin-bottom: 20px;
  position: relative;
}

#content-wrapper {
  scroll-margin: 20px;
}

.chat-text {
  display: flex;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
}

.chat-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #606060;
  .close-button {
    font-size: 20px;
    cursor: pointer;
  }
}

.mailto-form {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.submit-message {
  position: absolute;
  bottom: 40px;
  height: 200px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .submit-content {
    color: #077db4;
    text-align: center;
  }
}

.chat-input {
  width: 100%;
  background-color: white;
  color: #077db4;
  border: 2px solid #077db4;
  padding: 10px;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
}

.mail-submit {
  background-color: #077db4;
  color: white;
  width: 150px;
  border: 2px solid #077db4;
  padding: 10px;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
}

.chat-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #2a7fb6;
  border-radius: 50%;
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
  }
}

.jot-wrapper {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  .jot-form--link {
    color: #077db4;
    font-weight: bold;
  }
}
