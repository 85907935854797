.loadingContainer {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}
.loadingPreImage {
  height: 324px;
  width: 100%;
}

.customArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
