.appointment-wrap {
  .appoint-sub-head-wrap {
    @include wrap-flx;
    justify-content: space-between;
    margin-bottom: 25px;
    h2 {
      font-weight: 500;
      letter-spacing: 0.5px;
      @include breakpoints(small) {
        width: 55%;
      }
    }
    &.prev-head {
      h2 {
        width: 100%;
      }
    }
    .filter-tab {
      @include filter-tab;
      @include breakpoints(small) {
        margin-top: 10px;
      }
    }
  }
  .appoint-req-record {
    @include medium-font;
    letter-spacing: 0.3px;
    .time-icon {
      margin-right: 7px;
      position: relative;
    }
    span {
      position: relative;
      &:last-child {
        @include bold-font;
        letter-spacing: 0.3px;
        margin-left: 15px;
        padding-left: 15px;
      }
      &:before {
        content: '|';
        position: absolute;
        left: 0;
        top: -4px;
      }
    }
  }
  .schedule-icons {
    text-align: right;
    @include medium-font;
    letter-spacing: 0.3px;
    @include breakpoints(small) {
      text-align: left;
    }
    span {
      position: relative;
      &:first-child {
        margin-right: 30px;
        padding-left: 35px;
        &:before {
          @include schedule-icons;
          top: -4px;
          background-position: -297px -309px;
        }
      }
      &:nth-child(2) {
        margin-right: 30px;
        padding-left: 35px;
        &:before {
          @include schedule-icons;
          top: -4px;
          background-position: -297px -412px;
        }
      }
      &:last-child {
        padding-left: 35px;
        &:before {
          @include schedule-icons;
          top: -4px;
          background-position: -446px -78px;
        }
      }
    }
  }
  .appoint-box {
    margin-top: 20px;
    @include box-shadow-style;
    border: 0;
    @include border-radius(10px);
    padding-bottom: 20px;
    @include breakpoints(medium) {
      padding: 15px 0;
      max-width: 100%;
      overflow-x: scroll;
    }
    @include breakpoints(small) {
      padding: 0 0 10px;
    }
    .appoint-box-content {
      max-height: 650px;
      overflow-y: scroll;
      margin: 10px 10px 0 0;
      padding-right: 20px;
      @include breakpoints(medium) {
        width: 1050px;
      }
      @include breakpoints(small) {
        width: 980px;
      }
      &::-webkit-scrollbar {
        width: 18px;
        @include breakpoints(medium) {
          width: 0px;
        }
      }
      &::-webkit-scrollbar-track {
        background-color: #e3e3e3;
        padding: 0 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        cursor: pointer;
        width: 10px;
        border: 4px solid #e3e3e3;
        @include breakpoints(medium) {
          width: 6px;
          border: 3px solid #e3e3e3;
          visibility: hidden;
        }
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #077db4;
      }
      .table-heading-wrap-device {
        @include appointment-table-width;
        display: none;
        div {
          width: 20%;
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 22%;
          }
          &:nth-child(4) {
            width: 18%;
          }
        }
        @include breakpoints(medium) {
          display: flex;
          div {
            width: 54px;
            &:nth-child(2) {
              width: 225px;
            }
            &:nth-child(3) {
              width: 202px;
            }
            &:nth-child(4) {
              width: 151px;
            }
          }
        }
      }
    }
    .table-heading-wrap {
      @include appointment-table-width;
      @include breakpoints(medium) {
        display: none;
      }
    }
    .table-content-appointment {
      @include appointment-table-width;
      border-width: 1px;
      div {
        width: 20%;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.7;
        letter-spacing: 0.58px;
        @include breakpoints(medium) {
          font-size: 13px;
        }
        &:nth-child(2) {
          width: 25%;
        }
        &:nth-child(3) {
          width: 22%;
        }
        &:nth-child(4) {
          width: 18%;
        }
        p {
          padding: 0;
          margin: 0;
          span {
            &:first-child {
              font-weight: 700;
              margin-right: 3px;
            }
          }
        }
        &:last-child {
          font-weight: 700;
          .schedule-status {
            li {
              position: relative;
              padding-left: 35px;
              margin-bottom: 10px;
              &.blank {
                &:before {
                  @include schedule-icons;
                  top: -2px;
                  background-position: -297px -451px;
                }
              }
              &.scheduled {
                &:before {
                  @include schedule-icons;
                  top: -2px;
                  background-position: -297px -309px;
                }
              }
              &.unscheduled {
                &:before {
                  @include schedule-icons;
                  top: -2px;
                  background-position: -297px -412px;
                }
              }
              &.cancelled {
                &:before {
                  @include schedule-icons;
                  top: -2px;
                  background-position: -446px -78px;
                }
              }
            }
            .switch-link {
              font-weight: 500;
              a {
                color: map-get($colors, primary-main);
              }
            }
          }
        }
      }
    }
  }
  .prev-file-wrap {
    @include box-shadow-style;
    @include border-radius(10px);
    border: 0;
    padding: 50px 0 0px;
    margin-bottom: 50px;
    @include breakpoints(small) {
      margin-bottom: 30px;
      padding: 20px 0 0;
    }
    .prev-file-box {
      padding: 0 30px 40px;
      border-bottom: 1px solid map-get($colors, profile-border);
      margin-bottom: 40px;
      @include medium-font;
      @include breakpoints(small) {
        margin-bottom: 20px;
        padding: 0 10px 20px;
      }
      &:last-child {
        margin-bottom: 0;
        border: 0;
      }
      a {
        border: 2px solid map-get($colors, primary-main);
        color: map-get($colors, primary-main);
        padding: 13px 35px 13px 40px;
        .xls-icon {
          position: relative;
          top: -3px;
          padding-right: 10px;
        }
        &:hover {
          background-color: map-get($colors, link-hover);
        }
      }
    }
  }
  .filter-btn {
    width: 100%;
    border: 0px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    border: solid 1px #7b7b7b;
    background-color: #f6f6f6;
    height: 43px;
    line-height: 43px;
    text-align: left;
    padding: 0px 24px;
    &:focus {
      outline: none;
    }
    span {
      &.filter-icon {
        padding-right: 8px;
      }
    }
  }
}
