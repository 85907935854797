.form-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-form {
  max-width: 400px;
  width: 400px;
  height: 350px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #077db4;
  border-radius: 4px;
}

.reg-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #077db4;
}

.reg-input[type='text'],
.reg-input[type='password'],
.reg-input[type='email'] {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: border-color 0.2s ease-in-out;
}

.reg-input[type='text']:focus,
.reg-input[type='password']:focus {
  outline: none;
  border-color: #077db4;
}

.reg-button[type='submit'] {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #077db4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
