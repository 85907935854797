// .container {
//     @include breakpoints('xlarge') {
//         max-width: 1440px;
//         padding-left: 0px;
//         padding-right: 0px;
//         .row {
//             max-width: 1200px;
//             margin-left: auto;
//             margin-right: auto;
//         }
//     }
// }

.container {
  max-width: 1440px;
  padding-left: 0px;
  padding-right: 0px;
}
.modal-content {
  border-radius: 0;
  border: 0;
  height: 100%;
}
.modal-dialog {
  overflow-x: hidden;
  max-width: fit-content !important;
  border: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: unset;
  margin: 0 auto;
  @include breakpoints(medium) {
    max-width: 85%;
  }
  @include breakpoints(small) {
    max-width: 90%;
    margin: 10% auto;
  }
}
.modal-header {
  position: absolute;
  border: 0;
  right: 0;
  top: 0;
  button.close {
    position: absolute;
    right: 12px;
    top: 0;
    font-size: 45px;
    font-weight: 300;
    z-index: 1;
    &:focus {
      outline: 0;
    }
    @include breakpoints(small) {
      top: 0;
    }
  }
}

.no-padding {
  padding: 0 0 0 0 !important;
}
.no-padding-for-small {
  @include breakpoints(small) {
    padding: 0 0 0 0 !important;
  }
}
.no-left-padding {
  padding-left: 0px !important;
}
.no-right-padding {
  padding-right: 0px !important;
}
.no-top-padding {
  padding-top: 0px !important;
}
.no-bottom-padding {
  padding-bottom: 0px !important;
}

.no-margin {
  padding: 0 0 0 0 !important;
}
.no-left-margin {
  margin-left: 0px !important;
}
.no-right-margin {
  margin-right: 0px !important;
}
.no-top-margin {
  margin-top: 0px !important;
}
.no-bottom-margin {
  margin-bottom: 0px !important;
}
a {
  color: map-get($colors, primary-main);
  &:hover {
    color: map-get($colors, primary-main);
  }
}
.no-border {
  border: 0px;
}
.no-shadow {
  box-shadow: unset;
}
.react-calendar {
  position: absolute;
  right: 0%;
  max-width: max-content;
  @include breakpoints(small) {
    max-width: 100%;
  }
}
.close-cal {
  position: absolute;
  right: -7px;
  top: -10px;
  width: 25px;
  height: 25px;
  background-color: map-get($colors, primary-main);
  cursor: pointer;
  text-align: center;
  @include border-radius(50px);
  z-index: 2;
  &:before {
    content: '';
    position: absolute;
    height: 2px;
    background-color: map-get($colors, white1);
    width: 60%;
    top: 12px;
    left: 5px;
    @include transform(rotate(45deg));
  }
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: map-get($colors, white1);
    width: 60%;
    top: 12px;
    left: 5px;
    @include transform(rotate(-45deg));
  }
}
.hide-for-medium {
  @include breakpoints(medium) {
    display: none !important;
  }
}
// .col-12{
//     padding: 0;
// }
// @include breakpoints(xlarge){
//     .col-12{
//         padding: 0 15px;
//     }
// }

.scrollbarDesign,
%scrollbarDesign {
  &::-webkit-scrollbar {
    width: 18px;
    @include breakpoints(medium) {
      width: 0px;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: #e3e3e3;
    padding: 0 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    cursor: pointer;
    width: 10px;
    border: 4px solid #e3e3e3;
    @include breakpoints(medium) {
      width: 6px;
      border: 3px solid #e3e3e3;
      visibility: hidden;
    }
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #077db4;
  }
}

.scrollbarDesign1,
%scrollbarDesign1 {
  &::-webkit-scrollbar {
    width: 18px;
    @include breakpoints(small) {
      width: 10px;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: #e3e3e3;
    padding: 0 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    cursor: pointer;
    width: 10px;
    border: 4px solid #e3e3e3;
    @include breakpoints(small) {
      width: 6px;
      border-width: 2px;
    }
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #077db4;
  }
}
.scrollbarDesign2,
%scrollbarDesign2 {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #cccccc;
  }
  &::-webkit-scrollbar-thumb {
    background: #606060;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #606060;
  }
}

.scrollbarDesign3,
%scrollbarDesign3 {
  &::-webkit-scrollbar {
    width: 8px;
    @include border-radius(50px);
  }
  &::-webkit-scrollbar-track {
    // background: #cccccc;
    // @include border-radius(50px);
    box-shadow: 0px 0px 5px #dfdfdf inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #077db4;
    @include border-radius(50px);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #077db4;
    @include border-radius(50px);
  }
}

.scrollbarDesign-hori,
%scrollbarDesign-hori {
  &::-webkit-scrollbar {
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #cccccc;
  }
  &::-webkit-scrollbar-thumb {
    background: #606060;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #606060;
  }
}
.scrollbarDesign-hori-slim,
%scrollbarDesign-hori-slim {
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #cccccc;
  }
  &::-webkit-scrollbar-thumb {
    background: #606060;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #606060;
  }
}

.rw-list {
  @extend %scrollbarDesign3;
}

.bold {
  font-weight: bold !important;
}
.capitalize {
  text-transform: capitalize;
}
.rw-widget-picker {
  min-height: unset;
  border: none;
  border-radius: none;
}
.rw-multiselect-input {
  height: unset;
  min-height: calc(38px - 1px * 2);
  padding: 14px 20px !important;
  width: 100%;
}
.input-pro.rw-multiselect.rw-widget {
  padding: 0;
}

.no-service {
  margin: 180px 0;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 1.79px;
  @include breakpoints(small) {
    margin: 50px 0;
    font-size: 23px;
    letter-spacing: 1px;
  }
  a {
    cursor: default;
  }
}
.react-calendar__month-view__days__day--weekend {
  color: map-get($colors, black_color);
}
.react-calendar__tile:disabled {
  &.react-calendar__month-view__days__day--weekend {
    color: rgba(16, 16, 16, 0.3);
  }
}
.cursor-default {
  cursor: default !important;
}
