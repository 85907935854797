
.question {
  border: 2px solid black;
  width: 100%;
  height: 80px;
  padding: 12px;
  color: black;
  display: flex;
  align-items: center;
  gap: 12px;
  &.blue {
    border-color: map-get($colors, primary-main);
    color: map-get($colors, primary-main);
  }
  &.gray {
    border-color: map-get($colors, grey-dark);
    color: map-get($colors, grey-dark);
  }
}

.link-active {
  height: 100%;
  width: 10px;
  background-color: map-get($colors, primary-main);
}

.answer-input {
  border: 2px solid black;
  width: 100%;
  height: 100px;
  padding: 12px;
  color: black;
}

.arrow {
  margin-left: auto;
  svg {
    width: 20px;
    height: 20px;
  }
  &.active {
    transform: rotate(180deg);
  }
}

.question-edit {
  margin-left: auto;
  color: map-get($colors, primary-main);
  cursor: pointer;
}