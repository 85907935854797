
.checkbox {
    display: flex;
    margin-top: 8px;
    cursor: pointer;
    input{
        border: 1px solid #ddd;
        background-color: #ffff;
        width: 20px;
        height: 20px;
        cursor: pointer;
        appearance: none;
        position: relative;

        &:before {
            content: "";
            display: inline-block;
            height: 20px;
            width: 20px;
        }
        &:checked {
            &:before {
                background-color: #1680b8;
                border: 1px solid #1680b8;
            }
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                top: 2px;
                left: 7px;
                width: 6px;
                height: 12px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }
    p {
        margin-left: 10px;
        font-size: 13px;
        letter-spacing: 0.6px;
    }
}