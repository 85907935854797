.scheduling-page {

    
    .heading {
        padding: 0px;
        
        // h3 {
        //     font-size: 24px;
        //     font-weight: 500;
        //     letter-spacing: 0.57px;
        //     margin-bottom: 30px;
        // }
        h4 {
            
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.38px;
            @include breakpoints(small){
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 15px;
            }
        }
    }
    .provider-form-inner {
        position: relative;
        padding: 0px;
        img {
            &.edit-icon {
                position: absolute;
                right: 50px;
                top: 5px;
                @include breakpoints(small) {
                    right: 0px;
                }
            }
        }
        .practice-hour-box {
            span {
                &:nth-child(1) {
                    width: 149px;                    
                    @include breakpoints('large') {
                        width: 130px;
                    }
                    @include breakpoints('small') {
                        margin-bottom: 10px;
                        width: 100%;
                    }
                }
                &:nth-child(2) {
                    width: 130px;
                    @include breakpoints(small){
                        width: 120px;
                    }
                }
                &:nth-child(3) {
                    width: 34px;
                }
                &:nth-child(4) {
                    width: 130px;
                    @include breakpoints(small){
                        width: 120px;
                    }
                }
            }
            &.custom-padding {

                select {
                    width: 284px;
                    padding: 15px 20px;
                    margin-left: 10px;
                    margin-right: 10px;
                     @include breakpoints('small') {
                        margin-left: 0px;
                        margin-right: 0px;
                        margin-bottom: 10px;
                        width: 100%;
                    }
                }
                @include breakpoints(small) {
                    width: 100%;
                    flex-direction: column;
                    align-items: baseline;
                }
            }
        }
        .myform {
            select, input {
                width:100%;
            }
            &.disabled {
                @extend %scheduling-page-font;
                opacity: 0.5;
                color: map-get($colors, rev-chk-label);
            }
        }
        &.member {
            margin: 0;
            width: 100%;
            & .number {
                position: absolute;
                top: 27px;
                font-size: 28px;
                font-weight: 500;
                letter-spacing: 0.67px;
            }
            
            & .section {
                padding-right: 0;
                padding-left: 35px;
                width: 100%;
               
            }
            &.provider-steps-wrap {
                margin-top: 46px;
                @include breakpoints(small){
                    margin-top: 30px;
                }
            }
        }
        
        
    }
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col {
        padding:0px;
    }
    p {
        line-height: 1.56;
        letter-spacing: 0.67px;
        a {
            color: map-get($colors, primary-main);
            font-weight: 500;
        }
    }
    .margin-30 {
        margin-top:30px;
        margin-bottom: 30px;
    }
    .provider-steps-wrap {
        .next-prev-btn-pro {
            margin-left: -50px;
            .prev-btn-pro, .next-btn-pro {
                @include breakpoints(medium) {
                    width: 215px;
                }
            }
            @include breakpoints(medium) {
                margin-left: 0px;
            }
        }
    }
    .button {
        &.disabled {
            
            padding: 15px 20px;
            margin: 78px auto 40px auto; 
            border-radius: 4px;
            background-color: map-get($colors, write-review);
            width: max-content;
            a {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                letter-spacing: 1.13px;
                color: map-get($colors, write-review-txt);
            }

        }
    }
    .profile-tabs {
        border-radius: 10px;
        padding: 10px 25px 0;
        @include breakpoints(large){
            padding: 10px 20px 0;
        }
        .nav-tabs {
            border-bottom: 0px !important;
            justify-content: unset;
            .nav-link {                
                @include breakpoints('medium') {
                    padding-top: 18px; 
                }
                @include breakpoints('small') {
                    padding-top: 10px; 
                    width: 140px;
                }
            }
        }
    }
    .info-icon{
        width: 22px;
        position: relative;
        // top: 9px;
        @include breakpoints(small){
            // top: 6px;
        }
    }
    .scheduling-component{
        max-height: unset;
    }
    .scheduling-component-main{
        .date-area{
            .date-column{
                // max-height: 580px;
                &.show-height{
                    max-height: unset;
                }
            }
        }
    }
    .pass-rules{
        @include breakpoints(small){
            font-size: 12px;
            ul{
                li{
                    list-style-position: outside;
                    margin-left: 15px;
                }
            }
        }
    }
}
.search-modal-wrap {
    min-width: 500px;
    .next-btn-pro {
        max-width: 100px;
    }
}
