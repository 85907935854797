.provider-detail-right-top{
    .patient-features{
        @include patient-features;
        padding-top: 85px;
        @include breakpoints(medium){
            padding: 0;
        }
    }
    .endorse-link{
        border-top:2px solid map-get($colors, profile-border);
        margin-top: 24px;
        padding-top: 40px;
        max-width: 100%;
        a, button{
            max-width: 355px;
            margin-left:auto;
            margin-right:auto;
        }
    }
    .book-appointment-wrap{
        h2{
            font-weight: 900;
            letter-spacing: 1.1px;
            margin-bottom: 10px;
            @include breakpoints(small){
                margin-bottom: 5px;
            }
        }
        .subhead-profile{
            @extend %profile-appointment;
            color: map-get($colors, grey-dark);
            margin-bottom: 25px;
            @include breakpoints(small){
                margin-bottom: 10px;
            }
        }
        .appointment-location{
            @extend %profile-appointment;
            color: map-get($colors, black1);
            margin-bottom: 20px;
            font-weight: 900;
            @include breakpoints(small){
                margin-bottom: 10px;
            }
        }
        .location-select{
            border-bottom: 2px solid map-get($colors, bright-gray);
            padding-bottom: 35px;
            margin-bottom: 35px;
            @include breakpoints(small){
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
            .myform{
                .select{
                    font-size: 16px;
                    letter-spacing: 0.6px;
                    background-image: url("#{$imagePath}drop-arrow-search.png");
                    background-size: 17px;
                    @include breakpoints(small){
                        font-size: 14px;
                        padding-right: 35px;
                    }
                }
            }
        }        
    }    
    .endorse-link-patient, .calender-wrap{
        // display: none;
    }
}