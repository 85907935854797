.contactus {
  @include box-shadow-style;
  padding: 35px 60px;
  min-height: 467px;
  margin-bottom: 40px;
  &.modal-msg {
    border: 0;
    padding: 0px 0;
    min-height: unset;
    width: 600px;
    background-color: transparent;
    box-shadow: none;
    .suc-msg {
      width: 100%;
      margin: 40px 0 40px;
      background-color: rgba($color: #ffffff, $alpha: 1);
      padding: 50px 0;
      box-shadow: inset 0px 0px 15px 0px rgba(50, 50, 48, 0.2);
      // box-shadow:         0px 0px 9px 0px rgba(50, 50, 48, 0.26);
      margin: 10px;
      span {
        display: block;
      }
      .next-btn-pro {
        width: auto;
        &.delete-btn {
          border: 1px solid map-get($colors, delete-color);
          background-color: map-get($colors, white1);
          color: map-get($colors, delete-color);
          //height: 52px;
          width: 251px;
        }
        &.cancel-btn {
          border: 1px solid map-get($colors, primary-main);
          // color: map-get($colors, primary-main);
          // background-color: map-get($colors, white1);
          //height: 52px;
          width: 251px;
          margin-top: 18px;
        }
      }
      .right-mark {
        margin-bottom: 20px;
      }
      button {
        &.close {
          padding: 0;
          margin: 0;
          font-size: 45px;
          font-weight: 300;
          line-height: 27px;
          position: absolute;
          top: 15px;
          right: 20px;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  @include breakpoints(medium) {
    padding: 35px 15px;
    //  &.modal-msg {
    //     border: 0;
    //     box-shadow: unset;

    // }
  }
  h3 {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 40px;
    @extend %aboutus-content-font;
    line-height: 1;
    font-weight: bold;
  }
  .pro-label {
    // @extend %contactus-label-font;
  }
  input {
    &::placeholder {
      @extend %contactus-placeholder-font;
      color: map-get($colors, rev-chk-label);
      opacity: 0.5;
    }
  }
  .next-btn-pro {
    margin-left: auto;
    margin-right: auto;
    a {
      color: #fff !important;
    }
  }
  .suc-msg,
  .error-msg {
    width: 73%;
    text-align: center;
    margin: auto;
    margin-top: 60px;

    h3 {
      margin: 0px;
      line-height: 36px;
      letter-spacing: 1px;
      @include breakpoints(small) {
        line-height: 26px;
      }
      @include breakpoints(small) {
        line-height: 30px;
      }
      &.italic {
        font-style: italic;
        @extend %contactus-error-font;
      }
      &.error-msg-404 {
        margin-bottom: 75px;
        &:first-child {
          margin-bottom: 45px;
        }
      }
    }

    ul {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      li {
        position: relative;
        &:not(:last-child) {
          &:after {
            content: '.';
            right: -5px;
            position: absolute;
            font-size: 32px;
            line-height: 21px;
            color: map-get($colors, grey-dark);
          }
          @include breakpoints(small) {
            &:after {
              content: '';
            }
          }
        }
        a {
          color: map-get($colors, primary-main);
          margin: 32px 10px;
          @extend %suc-anchor-font;
          cursor: pointer;
          &:hover {
            color: map-get($colors, primary-main);
          }
        }
      }
      @include breakpoints(small) {
        flex-direction: column;
      }
    }
    .request-appointment {
      margin-top: 20px;
      display: flex;
      margin-left: -50px;
      margin-right: -50px;
      align-items: baseline;
      flex-wrap: wrap;
      justify-content: center;
      @include breakpoints(large) {
        flex-direction: column;
        align-items: center;
      }
      h5 {
        @extend %suc-anchor-font;
        letter-spacing: 0.83px;
        color: map-get($colors, grey-dark);
      }
      ul {
        margin-top: 0px;
      }
    }
    .cus-check {
      & label {
        &:before {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background-color: map-get($colors, success-green);
          border: unset;
        }
        &:after {
          top: 16px;
          left: 30px;
          width: 12px;
          height: 26px;
        }
      }
    }
    &.pass-recovery {
      width: 67%;
      @include breakpoints(medium) {
        width: 100%;
        .prev-btn-pro {
          margin-right: 10px;
        }
      }
    }
    @include breakpoints(medium) {
      width: 100%;
      margin-top: 20px;
    }
  }
  .textarea-contact {
    height: 100px;
  }
}
.appointment-submit {
  margin-top: 30px;
  .contactus {
    @include box-shadow(unset);
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
    .suc-msg {
      margin-top: 45px;
      padding: 0 3%;
      width: 81%;
      @include breakpoints(small) {
        margin-top: 15px;
        padding: 0;
        h3 {
          font-size: 16px;
        }
      }
    }
    @include breakpoints(small) {
      padding: 0;
    }
  }
  @include breakpoints(small) {
    .profile-tabs {
      padding: 10px 0 0;
    }
  }
}
