h1 {
  margin: 30px 0 38px;
  font-weight: 700;
  letter-spacing: 1.17px;
  @include breakpoints(medium) {
    margin: 20px 0 10px;
  }
}

.modal-search-container {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.modal-search-button-container {
  width: 100%;
}

.modal-search-button {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: 24px 20px;
  background-color: #077db4;
  border-radius: 5px;
  color: #fff;
  width: 28%;
}

.search-top-fields {
  @include wrap-flx;
  justify-content: space-between;
  span {
    position: relative;
    &:nth-child(1) {
      width: 42%;
      input {
        padding-left: 60px;
        @include breakpoints(small) {
          padding-left: 45px;
        }
      }
      @include breakpoints(small) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    &:nth-child(2) {
      width: 30%;
      input[type='text'] {
        padding-left: 65px;
        @include breakpoints(small) {
          padding-left: 45px;
        }
      }
      @include breakpoints(small) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    &:nth-child(3) {
      width: 22%;
      @include breakpoints(small) {
        width: 100%;
      }
    }
  }
  .input-home {
    @include input-home;
  }
  .search-home-btn {
    @include search-home;
    max-height: 63px !important;
  }
  .find-home {
    @include find-home;
  }
}
.scheduling-component-main {
  .title-area {
    margin-bottom: 20px;
    align-items: center;
  }
}
.sponsor-doctor-list {
  .request-appoint {
    display: block;
    @include breakpoints(small) {
      .next-btn-pro {
        width: 100%;
      }
      margin: 0;
    }
  }
  .patient-features,
  .endorse-link {
    display: none;
  }
  .endorse-link-patient {
    display: block;
  }
}
.top-doctor-list {
  .patient-features {
    display: none;
  }
  .scheduling-box {
    display: block;
    .scheduling-component-main {
      &:first-child {
        display: none;
      }
      &:last-child {
        margin-top: 5px;
      }
    }
  }
  .patient-features,
  .endorse-link {
    display: none;
  }
  .endorse-link-patient {
    display: block;
    a {
      &:last-child {
        display: none;
      }
    }
  }
}
.other-doctor-list {
  .patient-features,
  .endorse-link {
    display: none;
  }
  .endorse-link-patient,
  .scheduling-features {
    display: block;
  }
}
.scheduling-component-main {
  .date-area {
    .date-column {
      .date-list {
        font-size: 13px;
        padding: 14px 2px;
        @include breakpoints(small) {
          padding: 5px;
          font-size: 11px;
        }
      }
    }
  }
}
// .col-12{
//     padding: 0 !important;
// }
.home-bottom-field-txt {
  display: none;
}
