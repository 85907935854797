.admin-dashboard {
  padding: 25px;
  background-color: #f5f6f9;
  min-height: 100vh;
  font-size: 16px;
  margin-right: 0;
  margin-top: 70px;
  @include breakpoints(large) {
    margin-top: 65px;
  }
  @include breakpoints(medium) {
    padding: 15px;
    @include font-size($body);
  }
  .row {
    &.top-views {
      .top-col {
        padding: 15px;
        border-radius: 4px;
        margin: 12.5px;
        margin-top: 0px;
        margin-bottom: 25px;
        background-color: map-get($colors, white1);
        width: calc(25% - 25px);
        display: flex;
        flex-wrap: wrap;
        @include breakpoints(medium) {
          width: calc(50% - 25px);
        }
        @include breakpoints(small) {
          width: 100%;
          margin-bottom: 10px;
        }
        .left-side {
          flex: 1;
          .view-head {
            display: block;
            letter-spacing: 0.83px;
            color: map-get($colors, thcolor);
            @include font-size($notification-description);
            font-weight: 500;
            margin-bottom: 9px;
            line-height: normal;
          }
          .blue {
            color: map-get($colors, primary-main);
          }
          .light-blue {
            color: map-get($colors, link-endorse);
          }
          .red {
            color: map-get($colors, light-red);
          }
          .light-green {
            color: map-get($colors, green-shade);
          }
          .round-fig {
            letter-spacing: 1.2px;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 18px;
            display: block;
            line-height: normal;
            @include breakpoints(medium) {
              font-size: 22px;
            }
          }
          .view-all {
            color: map-get($colors, primary-main);
            @extend %cookies-body-font;
            text-transform: capitalize;
            line-height: normal;
          }
        }
        .right-side {
          width: 66px;
          height: 66px;
        }
      }
    }
    &.top-graph {
      .growth-analy,
      .appoiny-cat {
        padding: 15px;
        border-radius: 4px;
        margin: 12.5px;
        margin-top: 0px;
        margin-bottom: 25px;
        background-color: map-get($colors, white1);
        width: calc(66.6666667% - 25px);
        @include wrap-flx;
        @include breakpoints(medium) {
          width: 100%;
          min-height: 250px;
          display: block;
        }
        .heading-wrap {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .report-heading {
            margin-left: 0px;
            letter-spacing: 1px;
            line-height: normal;
            font-size: 18px;
            font-weight: bold;
            color: map-get($colors, thcolor);
            align-self: flex-start;
            flex: 1;
            @include breakpoints(small) {
              flex: none;
              align-self: normal;
              width: 65%;
            }
          }
          .color-sign {
            display: flex;
            flex-wrap: wrap;
            margin: 0 10px;
            align-self: flex-start;
            justify-content: center;
            color: map-get($colors, thcolor);
            font-size: 12px;
            font-weight: 500;
            @include breakpoints(small) {
              font-size: 11px;
              justify-content: flex-end;
              width: 100%;
              margin-top: 10px;
            }
            .patient-sign-ups,
            .provider-sign-ups,
            .revenue {
              margin-right: 2px;
              padding: 0px 20px 0px 22px;
              text-align: center;
              position: relative;
              @include breakpoints(small) {
                padding: 0px 4% 0px 17px;
                text-align: right;
              }
              &::before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                position: absolute;
              }
            }
            .patient-sign-ups {
              &::before {
                background-color: map-get($colors, primary-main);
              }
            }
            .provider-sign-ups {
              &::before {
                background-color: map-get($colors, green-shade);
              }
            }
            .revenue {
              padding-right: 0;
              margin-right: 0;
              &::before {
                background-color: map-get($colors, light-red);
              }
            }
          }
          .report-drop-date {
            margin-right: 0px;
            color: map-get($colors, thcolor);
            font-size: 12px;
            align-self: flex-start;
            margin-left: auto;
            position: relative;
            @include breakpoints(small) {
              position: absolute;
              top: 18px;
              right: 14px;
            }
            .select-time {
              border: 0px;
              background: no-repeat;
              text-align: right;
              text-align-last: right;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              padding-right: 20px;
              z-index: 5;
            }
            .select-icon {
              position: absolute;
              right: 0;
              z-index: 0;
              pointer-events: none;
            }
          }
        }
      }
      .appoiny-cat {
        width: calc(33.3333333% - 25px);
        @include breakpoints(medium) {
          width: 100%;
        }
        .circle-chart {
          width: 100%;
          a.canvasjs-chart-credit {
            display: none;
          }
        }
        .app-color-sign {
          display: none;
          width: 100%;
          grid-template-columns: repeat(3, 1fr);
          align-content: space-between;
          grid-column-gap: 15px;
          margin-top: 15px;
          > span {
            padding: 12px;
            font-size: 13px;
            font-weight: 500;
            color: map-get($colors, thcolor);
            line-height: normal;
            border: 1px solid #e1e1e1;
            text-align: left;
            border-radius: 4px;
            position: relative;
            padding-left: 28px;
            &::before {
              content: '';
              display: block;
              width: 12px;
              height: 12px;
              border-radius: 50px;
              top: 50%;
              left: 10px;
              transform: translateY(-50%);
              position: absolute;
            }
            &.child-sign {
              &::before {
                background-color: map-get($colors, primary-main);
              }
            }
            &.dental-sign {
              &::before {
                background-color: map-get($colors, green-shade);
              }
            }
            &.adults-sign {
              &::before {
                background-color: map-get($colors, light-red);
              }
            }
          }
        }
      }
    }
    &.bg-white-report {
    }
    .edit-tab-list {
      width: 1005px;
      li {
        cursor: pointer;
        border-radius: 4px;
        border: solid 1.5px map-get($colors, grey-dark);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.8px;
        color: map-get($colors, grey-dark);
        padding: 18px 10px;
        text-align: center;
        margin-right: 10px;
        margin-bottom: 20px;
        position: relative;
        transition: all 0.2s ease-in-out;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          transition: all 0.2s ease-in-out;
          color: map-get($colors, primary-main);
          border: solid 1.5px map-get($colors, primary-main);
          &:after {
            transition: all 0.2s ease-in-out;
            content: '';
            border-radius: 2px;
            height: 4px;
            width: 100%;
            position: absolute;
            bottom: -14px;
            left: 50%;
            transform: translateX(-50%);
            background-color: map-get($colors, primary-main);
          }
        }
      }
    }
    .view-all {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 0%;
        background-color: map-get($colors, primary-main);
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        transition: all 0.2s ease-out 0s;
        &:after {
          width: 100%;
        }
      }
    }
  }
  .dash-section {
    .revenue-block {
      display: none;
    }
  }
  .revenue-section {
    .dash-block {
      display: none;
    }
    .top-views {
      .top-col {
        .revenue-block {
          .round-fig {
            font-size: 24px;
          }
        }
      }
    }
  }
  .table-report {
    padding: 30px 30px 20px;
    border-radius: 4px;
    margin: 12.5px;
    margin-top: 0px;
    margin-bottom: 25px;
    background-color: map-get($colors, white1);
    width: calc(100% - 25px);
    min-height: 50vh;
    @include breakpoints(large) {
      padding: 10px;
    }
    @include breakpoints(small) {
      margin-bottom: 10px;
    }
    .back-link {
      color: map-get($colors, grey-dark);
      padding: 0px 8px;
      .back-icon {
        position: absolute;
        left: 0;
      }
    }
    .heading-wrap {
      display: flex;
      flex-wrap: wrap;
      .table-heading {
        color: map-get($colors, thcolor);
        margin-left: 0px;
        @extend %table-heading-font;
        flex: 1;
        .normal-text {
          font-weight: normal;
        }
      }
      .table-view-all {
        margin-left: auto;
        margin-right: 0px;
        text-align: right;
        .view-all {
          letter-spacing: 0.5px;
          color: map-get($colors, primary-main);
          @include breakpoints(small) {
            font-size: 14px;
          }
        }
      }
    }
    .table-report-scroll {
      overflow-x: auto;
      margin-top: 25px;
      @include breakpoints(small) {
        margin-top: 15px;
      }
      @extend %scrollbarDesign-hori;
      .table {
        border: 0px;
        border-left: 1px solid #f3f3f3;
        border-right: 1px solid #f3f3f3;
        margin: auto;
        thead {
          background-color: #f5f6f9;
          th {
            padding: 18px;
            color: map-get($colors, thcolor);
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            border: 0px;
            vertical-align: middle;
            min-width: fit-content;
            white-space: nowrap;
            @include breakpoints(large) {
              font-size: 14px;
            }
            &:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            &:last-child {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
            &.data-sort {
              position: relative;
              padding-right: 25px;
            }
          }
        }
        tbody {
          td,
          th {
            color: #606060;
            font-size: 16px;
            line-height: normal;
            padding: 24px 18px;
            border: 0px;
            vertical-align: middle;
            white-space: nowrap;
            border-bottom: 1px solid #f5f6f9;
            @include breakpoints(large) {
              font-size: 14px;
            }
            .active-toggle {
              position: relative;
              display: inline-block;
              width: 42px;
              height: 15px;
              input {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked {
                  & + .slider {
                    background-color: #c7e6f4;
                    &:before {
                      background-color: map-get($colors, primary-main);
                      -webkit-transform: translate(23px, -50%);
                      -ms-transform: translate(23px, -50%);
                      transform: translate(26px, -50%);
                    }
                  }
                }
              }
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #c9c9c9;
                -webkit-transition: 0.4s;
                transition: 0.4s;
                border-radius: 7.5px;
                &:before {
                  position: absolute;
                  content: '';
                  width: 23px;
                  height: 23px;
                  border-radius: 100px;
                  left: 0px;
                  top: 50%;
                  background-color: #989898;
                  -webkit-transition: 0.4s;
                  transition: 0.4s;
                  transform: translateY(-50%);
                }
              }
            }
            .table-action {
              line-height: normal;
              letter-spacing: 0.5px;
              color: map-get($colors, primary-main);
              font-size: 14px;
              text-transform: capitalize;
              margin-left: 12.5px;
              margin-right: 12.5px;
              position: relative;
              &:before {
                content: '|';
                position: absolute;
                left: -13px;
                color: #606060;
                top: 49%;
                transform: translateY(-50%);
              }
              &:first-child {
                margin-left: 0px;
                &:before {
                  display: none;
                }
              }
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
    .report-page {
      letter-spacing: 0.5px;
      color: #606060;
      margin-top: 15px;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      span {
        align-self: center;
        @include breakpoints(small) {
          width: 100%;
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
      .pagenation-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        margin-left: auto;
        margin-right: 0;
        flex: 1;
        justify-content: flex-end;
        @include breakpoints(small) {
          margin-left: 0;
          justify-content: center;
        }
        a {
          color: map-get($colors, grey-dark);
          padding: 6px 12px;
          line-height: 1.56;
          letter-spacing: 0.67px;
          font-size: 16px;
          font-weight: bold;
          border-radius: 4px;
          position: relative;
          @include breakpoints(large) {
            font-size: 14px;
          }
          @include breakpoints(small) {
            font-size: 12px;
            padding: 5px;
          }
          &.prev {
            &:before {
              content: '<';
              padding-right: 8px;
            }
          }
          &.next {
            &:after {
              content: '>';
              padding-left: 8px;
            }
          }
          &:hover,
          &.active {
            color: map-get($colors, white1);
            background-color: map-get($colors, grey-dark);
          }
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          li {
            margin: 0 5px;
            line-height: 2.8;
            a {
              padding: 6px 12px;
              letter-spacing: 0.75px;
              font-size: 18px;
              font-weight: bold;
              line-height: 1.39;
              border-radius: 4px;
              display: block;
              @include breakpoints(large) {
                font-size: 14px;
              }
              @include breakpoints(small) {
                font-size: 12px;
                padding: 5px 10px;
              }
            }
          }
        }
      }
    }
  }
}
.table-sort-filter {
  width: calc(100% + 30px);
  margin-top: 20px;
  .filter-input {
    display: inline-block;
    max-width: 445px;
    width: 100%;
    position: relative;
    @include breakpoints(small) {
      max-width: 100%;
    }
    .input-pro {
      height: 43px;
      line-height: 43px;
      padding: 0px 20px;
      border-radius: 3px;
      padding-right: 40px;
    }
  }
  .sort-select {
    display: inline-block;
    max-width: 160px;
    width: 100%;
    position: relative;
    @include breakpoints(medium) {
      max-width: 165px;
    }
    @include breakpoints(small) {
      max-width: 48%;
    }
    .input-pro {
      height: 43px;
      line-height: 40px;
      padding: 0px 20px;
      border-radius: 3px;
      color: map-get($colors, thcolor);
      background-color: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .select-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
    .filter-btn {
      width: 100%;
      border: 0px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
      border: solid 1px #7b7b7b;
      background-color: #f6f6f6;
      height: 43px;
      line-height: 43px;
      text-align: left;
      padding: 0px 24px;
      &:focus {
        outline: none;
      }
      span {
        &.filer-icon {
          padding-right: 8px;
        }
      }
    }
  }
  .link-btn {
    @include font-size($body);
    font-weight: 400;
    color: map-get($colors, white1);
    background-color: map-get($colors, primary-main);
    border-radius: 4px;
    padding: 0px 25px;
    line-height: 43px !important;
    height: 43px;
    display: inline-block;
  }
  .add-patient-btn {
    @include font-size($body);
    font-weight: 600;
    margin-right: 0;
  }
}
.provider-form-edit {
  .action {
    .link-btn {
      border: 1px solid map-get($colors, primary-main);
      &.link-btn-white {
        background-color: map-get($colors, white1);
        color: map-get($colors, primary-main);
      }
    }
  }
  .input-pro {
    border-radius: 3px;
  }
  .radio-button {
    position: relative;
    padding-left: 32px;
    font-size: 14px;
    color: map-get($colors, rev-chk-label);
    min-width: 130px;
    cursor: pointer;
    line-height: 2;
    input[type='radio'] {
      position: absolute;
      width: 0;
      height: 0;
      visibility: hidden;
      opacity: 0;
      & + .radio-check {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        border: 1px solid #dddddd;
        border-radius: 100px;
        transition: all 0.2s ease-in-out;
        &::after {
          display: none;
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: map-get($colors, primary-main);
          border-radius: 100px;
          transition: all 0.2s ease-in-out;
        }
      }
      &:checked + .radio-check {
        border: 1px solid map-get($colors, primary-main);
        &::after {
          display: block;
        }
      }
    }
  }
  .custom-checkbox {
    position: relative;
    padding-left: 32px;
    font-size: 14px;
    color: map-get($colors, rev-chk-label);
    line-height: 2;
    margin-right: 4px;
    margin-bottom: 20px;
    padding-right: 10px;
    max-width: 170px;
    width: 100%;
    cursor: pointer;
    .checkmark {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid #dddddd;
      transition: all 0.2s ease-in-out;
      &:before {
        content: '';
        left: 8px;
        top: 2px;
        display: none;
        width: 7px;
        height: 13px;
        border: solid map-get($colors, primary-main);
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        transition: all 0.2s ease-in-out;
      }
    }
    input[type='checkbox'] {
      position: absolute;
      height: 0;
      width: 0;
      visibility: hidden;
      opacity: 0;
      &:checked + .checkmark {
        border: 1px solid map-get($colors, primary-main);
        &:before {
          display: block;
        }
      }
    }
  }
  .basic-multi-select .select__control {
    padding: 8px 20px;
  }
  .select {
    background-color: map-get($colors, white1);
    padding: 14px 20px;
    padding-right: 30px;
    font-size: 14px;
    border-radius: 3px;
    color: map-get($colors, black1);
    border: solid 1px #dddddd;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .select-option {
    position: relative;
    .select-option-icon {
      pointer-events: none;
      position: absolute;
      right: 30px;
      top: 66px;
      transform: translateY(-50%);
      display: inline-block;
      svg {
        width: 15px;
      }
    }
  }
  .font-13 {
    font-size: 13px;
  }
  .blue-area {
    background-color: #eef7fb;
    padding: 15px 0px;
    display: flex;
    flex-wrap: wrap;
    .blue-head {
      line-height: 1;
      letter-spacing: 0.48px;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .plan-payment {
    .blue-area {
      margin: 0 15px;
      flex: 0 0 auto;
      max-width: calc(33.33% - 30px);
      padding: 24px 15px;
      @include breakpoints(small) {
        max-width: calc(100% - 30px);
      }
    }
    .plan,
    .payment {
      width: 100%;
      text-align: center;
      color: map-get($colors, black1);
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.48px;
    }
    .payment {
      margin-top: 18px;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.tabs {
  margin-bottom: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  .tab {
    cursor: pointer;
    &:nth-child(2) {
      margin-left: 20px;
    }
  }
  .active {
    color: #077db4;
    border-bottom: 1.5px solid #077db4;
  }
}

.reviews-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 40px;
  .reviews-headings {
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .empty {
      width: 18%;
    }
    .reviews-heading {
      width: 26%;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .reviews-content {
    width: 100%;
    .save-button {
      width: 100px;
    }
    .totals-value {
      width: 100%;
      font-weight: bold;
      font-size: 18px;
      text-align: right;
    }
    .doc-sum {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
      .review-title {
        width: 18%;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
      .review-container {
        width: 26%;
        display: flex;
        .review-wrapper {
          display: flex;
          justify-content: center;

          padding: 8px 0;
          width: 180px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 4px;
        }
      }
      .review-container--empty {
        width: 26%;
      }
    }
    .review {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;

      .review-title {
        width: 18%;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
      .review-value {
        width: 18%;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
      .review-container {
        width: 26%;
        display: flex;
        padding-right: 42px;
        position: relative;
        .error-input {
          position: absolute;
          bottom: -19px;
          left: 2px;
        }

        .review-wrapper {
          display: flex;
          justify-content: center;

          padding: 8px 0;
          width: 180px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 4px;
        }
      }
    }
  }
}

.digital-reputation-wrap {
  .digital-reputation-top {
    max-width: 90%;
    margin-bottom: 50px;
    @include breakpoints(medium) {
      max-width: 100%;
      margin-bottom: 20px;
    }
    .provider-form-edit {
      .select-option {
        .select-option-icon {
          right: 12px;
        }
      }
    }
  }
  .date-field {
    position: relative;
    .admin-cal-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      width: 25px;
      transform: translateY(-50%);
    }
  }
  .text-area {
    height: 100px;
    max-width: 920px;
  }
}

.bottom-btns {
  .next-prev-btn-pro {
    justify-content: flex-start;
    .prev-btn-pro,
    .next-btn-pro {
      font-size: 14px;
      width: 95px;
      padding: 10px 0;
      @include border-radius(5px);
      font-weight: 500;
      letter-spacing: 1px;
      margin: 0;
    }
    .prev-btn-pro {
      border: 1px solid map-get($colors, primary-main);
      color: map-get($colors, primary-main);
      margin-right: 12px;
      background-color: map-get($colors, white1);
      padding: 9px 0 !important;
      &:hover {
        background-color: map-get($colors, white1);
      }
    }
  }
}
.comment-digital-repo {
  width: 290px;
  white-space: normal;
}
.data-sort-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.sort-outer {
  position: relative;
  .data-sort-icon {
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.speciality-filter {
  @include wrap-flx;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .filter-input {
    width: 50%;
    position: relative;
    @include breakpoints(small) {
      width: 100%;
      margin-bottom: 10px;
    }
    .input-pro {
      @include border-radius(3px);
    }
  }
  @include breakpoints(small) {
    .speciality-links {
      width: 100%;
      margin-top: 5px;
    }
  }
}

.speciality-links {
  a {
    color: map-get($colors, primary-main);
    @extend %body;
    font-weight: 500;
    margin: 0 12.5px;
    position: relative;
    &:before {
      content: '|';
      position: absolute;
      left: -13px;
      color: map-get($colors, grey-dark);
      top: 49%;
      transform: translateY(-50%);
      font-weight: 400;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 1px;
      width: 0%;
      background-color: map-get($colors, primary-main);
      @include transition(all 0.2s ease-in-out);
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
    &:first-child {
      margin-left: 0;
      &:before {
        display: none;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

// .radio-tick {
// 	width: 20px;
// 	height: 20px;
//     position: relative;
//     text-align: center;
// 	.checkbox {
// 		display: inline-block;
// 		width: 20px;
// 		height: 20px;
//         border:1px solid map-get($colors, radio-tick);
//         @include border-radius(100%);
//         cursor: pointer;
// 		&:after {
// 			content: "";
// 			position: absolute;
// 			width: 0px;
// 			height: 2px;
// 			background: map-get($colors, primary-main);
// 			transform: translate(-2px, 12px) rotate(-45deg);
// 			transform-origin: left;
// 			transition: all 0.1s;
// 			transition-delay: 0ms;
// 		}
// 		&:before {
// 			content: "";
// 			position: absolute;
// 			width: 0px;
// 			height: 2px;
// 			background: map-get($colors, primary-main);
// 			transform: translate(-5px, 8px) rotate(45deg);
// 			transform-origin: left;
// 			transition: all 0.1s;
// 			transition-delay: 0.1s;
// 		}
//     }
//     input[type=radio] {
// 		display: none;
// 	}
// 	input[type=radio]:checked {
// 		& ~ .checkbox {
//             border-color:map-get($colors, primary-main);
//             background-color:#f1fbff;
// 			&:before {
// 				width: 5px;
// 				transition-delay: 0ms;
// 			}
// 			&:after {
// 				width: 10px;
// 				transition-delay: 0.1s;
// 			}
// 		}
// 	}
// }

.radio-tick {
  position: relative;
  .checkbox {
    cursor: pointer;
    margin: 0;
    padding: 0 0 0 28px;
    span {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      top: 0;
      border: 1px solid map-get($colors, radio-tick);
      @include border-radius(100%);
      transition: all 0.1s;
      &:after {
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        background: map-get($colors, primary-main);
        transform: translate(7px, 12px) rotate(-45deg);
        transform-origin: left;
        transition: all 0.1s;
        transition-delay: 0ms;
        left: 0;
      }
      &:before {
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        background: map-get($colors, primary-main);
        transform: translate(4px, 8px) rotate(45deg);
        transform-origin: left;
        transition: all 0.1s;
        transition-delay: 0.1s;
        left: 0;
      }
    }
  }
  input[type='radio'] {
    display: none;
  }
  input[type='radio']:checked {
    & ~ .checkbox {
      span {
        border-color: map-get($colors, primary-main);
        background-color: #f1fbff;
        &:before {
          width: 5px;
          transition-delay: 0ms;
        }
        &:after {
          width: 10px;
          transition-delay: 0.1s;
        }
      }
    }
  }
}
.radio-tick-center {
  .checkbox {
    span {
      right: 0;
      margin: 0 auto;
    }
  }
}

.check-tick {
  position: relative;
  .checkbox {
    cursor: pointer;
    margin: 0;
    padding: 0 0 0 28px;
    span {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 0;
      top: 0;
      border: 1px solid map-get($colors, radio-tick);
      transition: all 0.1s;
      background-color: map-get($colors, white1);
      &:after {
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        background: map-get($colors, primary-main);
        transform: translate(9px, 13px) rotate(-45deg);
        transform-origin: left;
        transition: all 0.1s;
        transition-delay: 0ms;
        left: 0;
      }
      &:before {
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        background: map-get($colors, primary-main);
        transform: translate(6px, 9px) rotate(45deg);
        transform-origin: left;
        transition: all 0.1s;
        transition-delay: 0.1s;
        left: 0;
      }
    }
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked {
    & ~ .checkbox {
      span {
        border-color: map-get($colors, primary-main);
        background-color: #f1fbff;
        &:before {
          width: 5px;
          transition-delay: 0ms;
        }
        &:after {
          width: 10px;
          transition-delay: 0.1s;
        }
      }
    }
  }
}
.check-tick-center {
  .checkbox {
    span {
      right: 0;
      margin: 0 auto;
    }
  }
}

.button-anchor {
  .white-btn {
    border: 1px solid map-get($colors, primary-main);
    color: map-get($colors, primary-main);
    margin-right: 12px;
    background-color: map-get($colors, white1);
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    display: block;
    text-align: center;
  }
}
.provider-management-outer {
  .table-sort-filter {
    .report-btn {
      display: inline-block !important;
    }
  }
}
.sort-filterwrap {
  @include wrap-flx;
  @include breakpoints(small) {
    justify-content: space-between;
  }
}

.tabs-admin-outer {
  width: 102%;
  overflow-x: auto;
  @extend %scrollbarDesign-hori-slim;
  margin-bottom: 5px;
  @include breakpoints(medium) {
    width: 100%;
  }
}
.patient-tabs-outer {
  .tabs-admin-outer {
    width: 100%;
    .edit-tab-list {
      width: 830px;
    }
  }
}
.notification-tabs-outer {
  .tabs-admin-outer {
    width: 100%;
    margin-bottom: 10px;
    .edit-tab-list {
      width: 100%;
    }
  }
}
.insurance-tabs-outer {
  .tabs-admin-outer {
    width: 100%;
    .edit-tab-list {
      width: 340px;
    }
  }
}
.speciality-tabs-outer {
  .tabs-admin-outer {
    width: 100%;
    .edit-tab-list {
      width: 710px;
    }
  }
}

// Provider Manage Info Modal
.provider-info-modal-outer {
  .provider-info-modal-wrap {
    width: 722px;
    margin-bottom: 25px;
    @include breakpoints(medium) {
      width: 100%;
    }
    @include breakpoints(small) {
      margin-bottom: 10px;
    }
    .provider-detail-left-top {
      padding: 25px 0 0;
      width: 100%;
      .provider-detail-box {
        @include wrap-flx;
        align-items: center;
        .provider-doc-img {
          width: 150px;
          height: 150px;
          margin: 0 37px 0 0;
          @include breakpoints(medium) {
            margin: 0 auto 10px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        .provider-doc-details {
          text-align: left;
          @include breakpoints(medium) {
            margin: 0 auto;
            text-align: center;
          }
          h5 {
            color: map-get($colors, black1);
          }
          .provider-designation {
            @include breakpoints(medium) {
              font-size: 14px;
            }
            span {
              font-weight: 400;
            }
          }
        }
        .provider-designation {
          margin: 10px 0;
        }
        .rating-result {
          .review-text {
            font-size: 12px;
            letter-spacing: 0.5px;
          }
        }
      }
      &.modal-med-edu {
        padding: 0;
        .edu-lang-wrap {
          ul {
            margin: 0;
            li {
              letter-spacing: 0.67px;
              color: map-get($colors, black1);
            }
          }
        }
      }
    }
    .heading-appointment-req {
      font-size: 20px;
      letter-spacing: 0.48px;
      color: map-get($colors, black1);
      font-weight: 500;
      margin: 30px 0 30px;
      @include breakpoints(medium) {
        font-size: 18px;
        margin: 20px 0 20px;
      }
      &.mhead-medical {
        margin: 25px 0;
      }
      &.location-head-medical {
        margin: 10px 0 25px;
      }
    }
    .appointment-req-area {
      .appoint-areabox {
        @include wrap-flx;
        justify-content: space-between;
        color: map-get($colors, black1);
        font-weight: 500;
        border-bottom: 2px solid #ececec;
        padding-bottom: 16px;
        margin-bottom: 18px;
        @include breakpoints(medium) {
          font-size: 14px;
        }
        .modal-appoint-left {
          width: 90%;
          @include breakpoints(medium) {
            width: 85%;
          }
          span {
            color: map-get($colors, grey-dark);
            margin-left: 6px;
            @include breakpoints(medium) {
              display: block;
            }
          }
        }
        .modal-appoint-right {
          width: 10%;
          text-align: right;
        }
      }
    }
    .pro-modal-focus-area {
      .profile-tabs {
        border: 0;
        box-shadow: unset;
        background-color: none;
        padding: 0;
        .focus-area-list {
          li {
            &:before {
              top: -19px;
            }
          }
        }
        li {
          border-bottom: 2px solid #ececec;
          padding-bottom: 16px;
          margin-bottom: 18px;
        }
      }
      &.pro-modal-location {
        .profile-tabs {
          .location-add-profile {
            @include wrap-flx;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-bottom: 20px;
            &:last-child {
              border-bottom: 2px solid #ececec;
              padding-bottom: 25px;
            }
            li {
              border: 0;
              padding: 0 0 0 30px;
              margin: 0;
              &:before {
                left: -5px;
              }
              &:first-child {
                width: 60%;
                @include breakpoints(medium) {
                  width: 50%;
                }
                @include breakpoints(medium) {
                  width: 100%;
                  margin-bottom: 20px;
                }
              }
              &:last-child {
                width: 40%;
                padding-left: 0;
                @include breakpoints(medium) {
                  width: 50%;
                  padding-left: 40px;
                }
                @include breakpoints(small) {
                  width: 100%;
                  padding-left: 30px;
                }
              }
            }
          }
        }
      }
      &.pro-modal-insurance {
        .profile-ins-list {
          li {
            border: 0;
            width: 50%;
            padding-bottom: 0;
            @include breakpoints(small) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

label.pro-label {
  color: #222222;
}

.input-pro {
  max-width: 955px;
}

.specialities-container {
  position: absolute;
  top: 200%; /* Adjust the top distance as needed */
  left: 0;
  background-color: #fff; /* Set the background color */
  border: 1px solid #ccc; /* Add a border for better visibility */
  padding: 10px; /* Adjust the padding as needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  z-index: 1000; /* Adjust the z-index to make sure it appears above other elements */
  max-height: 500px; /* Set a maximum height for inner scroll */
  overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute items evenly in each row */
}

/* Style for individual checkboxes */
.specialities-container div {
  width: calc(
    50% - 5px
  ); /* Set 25% width for each checkbox container with 5px margin between them */
  box-sizing: border-box;
  margin-bottom: 5px; /* Add some space between the checkboxes */
}

/* Style for individual checkboxes */
.specialities-container input[type='checkbox'] {
  margin-right: 5px; /* Add some space between the checkbox and label */
  margin-bottom: 5px; /* Add some space between the checkboxes */
}

.specialities-container input[type='checkbox']:checked {
  background: #007bff !important; /* Set the background color to blue when checked */
  color: #fff; /* Set the text color to white when checked */
}

/* Style for the "Apply Filters" button */
.specialities-container button {
  margin-top: 10px; /* Adjust the top margin as needed */
  padding: 5px 10px; /* Adjust the padding as needed */
  background-color: #077db4; /* Set the background color */
  color: #fff; /* Set the text color */
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 4px;
}

.specialities-container button:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #077db4; /* Change the background color on hover */
}

.spec-button {
  border: none;
  background-color: transparent;
  color: #222222;
  font-weight: 500;
}

.no-results {
  height: 600px;
  display: flex;
  span {
    font-size: 30px;
    font-weight: 500;
  }
}
