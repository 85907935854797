.scheduling-component {
  padding: 40px 20px 20px;
  box-shadow: 0 4px 14px 0 #e4eef3;
  max-height: 1314px;
  border: solid 1px map-get($colors, doc-box-border);
  background-color: map-get($colors, white1);
  @include breakpoints(small) {
    padding: 20px 0 0 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    box-shadow: none;
  }
  &-main {
    .title-area {
      margin-bottom: 30px;
      .title {
        font-size: 19px;
        font-weight: bold;
        letter-spacing: 0.83px;
      }
      .cal {
        text-align: right;
        height: 20px;
        z-index: 2;
        a {
          font-size: 16px;
          letter-spacing: 1.14px;
          text-align: center;
          color: map-get($colors, filter-color);
          img {
            margin-right: 10px;
            width: 20px;
            @include breakpoints(small) {
              margin-right: 0;
            }
          }
          &.cal-link {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid map-get($colors, primary-main);
            @include border-radius(4px);
            padding: 8px 15px;
          }
        }
        @include breakpoints(medium) {
          text-align: left;
          margin-top: 0;
        }
      }
    }

    .show-height1 {
      height: auto !important;
    }
    .date-area,
    .date-area-more,
    .date-area-months {
      position: relative;
      justify-content: center;
      @include wrap-flx;
      overflow: hidden;
      height: 300px;
      img {
        position: absolute;
        &.left-arrow {
          left: -0;
          top: 7px;
          z-index: 1;
          @include breakpoints(large) {
            // left: -20px;
            width: 20px;
          }
          @include breakpoints(medium) {
            left: 0px;
          }
          @include breakpoints(small) {
            // left: -12px;
            width: 16px;
          }
        }
        &.right-arrow {
          right: -0;
          top: 7px;
          z-index: 1;
          @include breakpoints(large) {
            // right: -20px;
            width: 20px;
          }
          @include breakpoints(medium) {
            right: 0px;
          }
          @include breakpoints(small) {
            // right: -12px;
            width: 16px;
          }
        }
      }
      .date-column {
        margin-right: 8px;
        width: 17.5%;
        overflow: hidden;
        max-height: unset;
        @include breakpoints(large) {
          max-height: 1107px;
        }
        @include breakpoints(medium) {
          max-height: 1103px;
        }
        @include breakpoints(small) {
          width: 16.2%;
          margin-right: 5px;
          max-height: 1100px;
        }
        // &:last-child {
        //   margin-right: 0;
        // }
        p {
          text-align: center;
          font-size: 13px;
          color: map-get($colors, grey-dark);
        }
        .date-heading {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-bottom: 17px;
          span {
            color: #000000;
            font-weight: bold;
            font-size: 16px;
            @include breakpoints(large) {
              font-size: 14px;
            }
            @include breakpoints(small) {
              width: 47px;
            }
            &:first-child {
              font-weight: normal;
              opacity: 0.6;
            }
          }
        }
        .date-list {
          padding: 14px 12px;
          width: 100%;
          text-align: center;
          background-color: map-get($colors, primary-main);
          border-radius: 3px;
          margin-bottom: 7px;
          color: map-get($colors, white1);
          font-size: 15px;
          font-weight: bold;
          @include breakpoints(large) {
            font-size: 13px;
            padding: 15px 5px;
          }
          @include breakpoints(small) {
            font-size: 13px;
            padding: 5px 5px;
          }
          &.disabled,
          &:disabled {
            color: map-get($colors, disabled);
            background-color: map-get($colors, write-review);
          }
        }
        button.date-list {
          border: none;
        }
        .booked {
          cursor: not-allowed;
        }
        .down-arrow-time {
          margin-top: 10px;
          text-align: center;
          background-color: map-get($colors, primary-main);
          border-radius: 3px;
          color: map-get($colors, white1);
          font-size: 14px;
          font-weight: 400;
          padding: 10px 0 11px;
          line-height: 1.3;
          cursor: pointer;
          div {
            position: relative;
            &:after {
              position: relative;
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid map-get($colors, white1);
              margin: 0 auto;
              @include breakpoints(small) {
                border-width: 7px;
              }
            }
            &:before {
              content: 'More';
            }
          }
        }
      }
    }
    .date-area-more {
      height: auto;
      .date-column {
        @include breakpoints(small) {
          margin: 0 2px;
          width: 18.4%;
          .down-arrow-time {
            padding: 5px;
            font-size: 12px;
          }
        }
        // &:last-child{
        //   margin-right: 8px;
        // }
      }
      &.date-area-less {
        position: relative;
        .date-column {
          .down-arrow-time {
            @include breakpoints(small) {
              padding: 5px 5px 3px;
            }
            div {
              &:before {
                position: relative;
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #ffffff;
                margin: 0 auto 2px;
                transform: rotate(180deg);
                @include breakpoints(small) {
                  border-width: 7px;
                }
              }
              &:after {
                // display: none;
                border-width: 0;
                content: 'Less';
                width: 100%;
                padding-bottom: 15px;
                // transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    .date-area-months {
      height: auto;
      margin-bottom: 10px;
      .date-column {
        @include breakpoints(small) {
          margin: 0 2px;
          width: 17.2%;
        }
        .date-heading {
          margin-bottom: 0;
          @include breakpoints(small) {
            span {
              width: auto;
            }
          }
        }
      }
    }
    .react-calendar {
      max-width: max-content;
      border: 1px solid #dcdcdc;
      @include border-radius(5px);
      width: 390px;
      @include breakpoints(small) {
        max-width: 300px;
        font-size: 14px;
      }
      abbr[title],
      abbr[data-original-title] {
        text-decoration: none;
        letter-spacing: 0.6px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Roboto';
        text-transform: capitalize;
        @include breakpoints(small) {
          font-size: 14px;
        }
      }
      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none;
      }
      .react-calendar__navigation button:enabled {
        &:hover {
          background-color: map-get($colors, white1);
        }
        &:focus {
          background-color: map-get($colors, white1);
        }
      }
      .react-calendar__navigation__next-button,
      .react-calendar__navigation__prev-button {
        background-image: url('#{$imagePath}cal-right-arrow.jpg');
        color: transparent;
        background-repeat: no-repeat;
        background-color: map-get($colors, white1);
        background-position: 0px 13px;
      }
      .react-calendar__navigation__prev-button {
        background-image: url('#{$imagePath}cal-left-arrow.jpg');
        background-position: 15px 13px;
      }
      .react-calendar__navigation button[disabled] {
        background-color: map-get($colors, white1);
        opacity: 0.5;
      }
      button:not(:disabled) {
        font-weight: 700;
        letter-spacing: 0.75px;
      }
      .react-calendar__tile {
        padding: 0.88em 0.5em;
      }
      .react-calendar__tile--active {
        @include border-radius(50%);
        background-color: map-get($colors, primary-main1);
        max-width: 12.2857% !important;
        @include breakpoints(small) {
          max-width: 13.2857% !important;
        }
      }
      .react-calendar__tile--active:enabled:hover {
        background-color: map-get($colors, primary-main1);
      }
      .react-calendar__tile:enabled {
        &:hover {
          @include border-radius(50%);
          background-color: map-get($colors, primary-main1);
        }
        &:focus {
          @include border-radius(50%);
          max-width: 12.2857% !important;
          @include breakpoints(small) {
            max-width: 13.2857% !important;
          }
        }
      }
      .react-calendar__tile--now {
        @include border-radius(50%);
      }
      .react-calendar__tile:disabled {
        background-color: white;
      }
      .react-calendar__tile--now {
        background-color: #d6d6d6;
        max-width: 12.2857% !important;
        @include breakpoints(small) {
          max-width: 13.2857% !important;
        }
      }
    }
  }
  .down-arrow-date {
    text-align: center;
    margin-top: 15px;
    .down-arrow-cal {
      width: 30px;
      cursor: pointer;
    }
  }
}
.calenderLoading {
  display: flex;
  justify-content: center;
}
.show-height {
  height: auto !important;
}
.view-cal-title {
  @include breakpoints(small) {
    display: none;
  }
}
