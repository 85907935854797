@include breakpoints(medium) {
  html {
    overflow-x: hidden;
  }
}
.logo-main {
  @include wrap-flx;
  align-items: center;
  .logo-icon {
    width: 36px;
    @include breakpoints(medium) {
      width: 24px;
    }
    @include breakpoints(small) {
      width: 28px;
    }
  }
  span {
    @extend %logo-text-font;
    position: relative;
    margin-left: 4px;
    @include breakpoints(small) {
      margin-left: 4px;
      font-size: 26px;
    }
  }
  @include breakpoints(small) {
    flex-wrap: nowrap;
  }
}
.header {
  position: relative;
  z-index: 50;

  background-color: map-get($colors, white1);
  .header-search-inner-before-login {
    align-items: center;
    display: flex;
    padding: 30px 0;
    .header-logo {
      // width: 18%;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1180px) {
        display: none;
      }
      @include breakpoints(medium) {
        display: none;
      }
      @include breakpoints(small) {
        display: none;
      }
      .logo-main {
        flex-wrap: nowrap;
      }
    }
  }
  .header-inner-before-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0 26px;
    @include breakpoints(medium) {
      padding: 15px 0;
    }
    @include breakpoints(small) {
      justify-content: space-between;
      padding: 15px 0 10px;
      align-items: normal;
    }
    .header-logo {
      @include breakpoints(medium) {
        display: none;
      }
      @include breakpoints(small) {
        display: flex;
        z-index: 2000;
        width: unset;
      }
      width: 30%;
      img {
        @include breakpoints(medium) {
          display: none;
        }
        @include breakpoints(small) {
          display: block;
        }
      }
      .logo-main {
        .logo-text {
          @include breakpoints(small) {
            display: none;
          }
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
  .header-inner {
    padding: 30px 0;
    position: relative;
    .header-left {
      padding: 0;
      width: 20%;
      @include breakpoints(medium) {
        width: 70%;
      }
      img {
        width: 246px;
        @include breakpoints(medium) {
          width: 180px;
        }
      }
    }
  }
  .sign-in-block {
    min-width: 120px;
    .sign-in-href {
      padding: 10px 38px 10px 20px !important;
    }
    @include create-sign;
    position: relative;
    @include breakpoints(small) {
      font-size: 12px;
    }
    .home-menu {
      @include home-menu;
      .home-menu-inner {
        @include home-menu-inner;
        a {
          .home-menu-links {
            color: #000000;
          }
          &:first-child {
            .home-menu-links {
              border-bottom: solid 1px #b9b9b9;
            }
          }
          &:nth-child(2) {
            .home-menu-links {
              border-bottom: solid 1px #b9b9b9;
            }
          }
        }
      }
    }
    &:hover {
      .home-menu {
        display: block;
      }
    }
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .create-acc-block {
    @include create-sign;
    line-height: 19px !important;
    margin-left: 10px;
    position: relative;
    display: flex;

    @include breakpoints(small) {
      font-size: 12px;
    }
    .home-menu {
      @include home-menu;
      right: 7px;
      @include breakpoints(small) {
        right: -15px;
      }
      .home-menu-inner {
        @include home-menu-inner;
        @include breakpoints(small) {
          &:before {
            right: 50px;
          }
        }
        .home-menu-links {
          width: 260px;
          color: #000000;
          @include breakpoints(small) {
            width: 215px;
          }
          &:first-child {
            border-bottom: solid 1px #b9b9b9;
          }
          &.no-border {
            border-bottom: 0;
          }
        }
      }
    }
    > a {
      letter-spacing: 0 !important;
      border: none;
      color: #545454;
      margin-right: 20px;
      position: relative;
      &:after {
        background: url('../../Icons/arrowDown.svg');
        height: 20px;
        width: 21px;
        top: 15%;
        left: auto;
        right: 0;
      }
      @include breakpoints(small) {
        margin-right: 0;
      }
    }
    &:hover {
      .home-menu {
        display: block;
      }
    }
  }
  .header-practice-link {
    @include header-center-link;
    border-right: 0.5px solid #ababab;
    line-height: 19px !important;
    padding-right: 20px;
  }

  .header-search-practice-link--search {
    .search-top-fields {
      flex-wrap: nowrap;
      span:nth-child(1) {
        width: 32%;
        margin-bottom: 0;
      }
      span:nth-child(2) {
        width: 28%;
        margin-bottom: 0;
      }
      span:nth-child(3) {
        width: 20%;
      }
      span:nth-child(4) {
        width: 14%;
        input {
          @media screen and (max-width: 767px) {
            height: unset;
            padding: 15px 4px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .header-search-practice-link {
    .row {
      margin-right: 0;
      margin-left: 40px;
      width: 100%;
    }
    width: 72.4%;
    display: flex;
    @include breakpoints(medium) {
      display: none;
    }
    @media screen and (max-width: 1220px) {
      width: 57%;
    }
  }
  .header-after-login {
    @include wrap-flx;
    justify-content: space-between;
    padding: 30px 0 26px;
    align-items: center;
    @include breakpoints(medium) {
      padding: 15px 0;
    }
    @include breakpoints(small) {
      padding: 15px 0 10px;
      flex-wrap: nowrap;
    }
    .header-logo {
      width: 30%;
      @include breakpoints(medium) {
        width: 45%;
      }
      @include breakpoints(small) {
        width: 100%;
        text-align: left;
      }
      img {
        // width: 246px;
        @include breakpoints(medium) {
          // width: 180px;
        }
      }
    }
    .header-search {
      width: 28%;
      @include breakpoints(large) {
        width: 24%;
      }
      @include breakpoints(medium) {
        width: 47%;
      }
      @include breakpoints(small) {
        width: 100%;
        margin-top: 15px;
        margin-right: 0;
      }
      position: relative;
      .search-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background-image: url(#{$imagePath}search-icon.png);
        width: 21px;
        height: 20px;
        border: 0;
        color: transparent;
        background-color: transparent;
        background-size: cover;
        @include breakpoints(medium) {
          width: 17px;
          height: 16px;
          right: 10px;
        }
      }
    }
    .mobile-menu {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 0;
      top: 35px;
      z-index: 11;
      cursor: pointer;
      display: none;
      @include breakpoints(small) {
        top: 25px;
        display: block;
      }
      .burger-menu {
        width: 100%;
        height: 100%;
        cursor: pointer;
        span {
          position: absolute;
          right: 0;
          top: 0;
          height: 3px;
          background-color: map-get($colors, primary-main);
          width: 100%;
          display: block;
          @include transition(all 0.1s ease-in-out);
          @include border-radius(10px);
          &:before {
            content: '';
            position: absolute;
            right: 0;
            top: -8px;
            height: 3px;
            background-color: map-get($colors, primary-main);
            width: 100%;
            @include transition(all 0.3s ease-in-out);
          }
          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 8px;
            height: 3px;
            background-color: map-get($colors, primary-main);
            width: 100%;
            @include transition(all 0.3s ease-in-out);
          }
        }
      }
      &.bmenu-active {
        .burger-menu {
          span {
            height: 0;
            &:before {
              @include transform(rotate(45deg));
              top: 0;
            }
            &:after {
              @include transform(rotate(-45deg));
              top: 0;
            }
          }
        }
      }
    }
    .header-right-log {
      width: 33%;
      @include breakpoints(large) {
        width: 38%;
      }
      @include breakpoints(medium) {
        width: 100%;
        margin-top: 30px;
      }
      @include breakpoints(small) {
        margin-top: 0px;
      }
      .account-icons-wrap {
        @include wrap-flx;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        @include breakpoints(small) {
          font-size: 12px;
          line-height: 15px;
          // flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
        }
        a {
          color: map-get($colors, grey-dark);
        }
        .dashboard-sec {
          width: 158px;
          @include breakpoints(small) {
            width: auto;
            text-align: center;
          }
          .dash-board-icon {
            @include sprite-header;
            background-position: -1px -32px;
            margin: 0 auto;
            padding-bottom: 35px;
            width: 32px;
            height: 27px;
            @include breakpoints(small) {
              padding-bottom: 0;
            }
          }
          &.active {
            a {
              color: map-get($colors, primary-main);
            }
            .dash-board-icon {
              background-position: -1px -79px;
            }
          }
          &:hover {
            a {
              color: map-get($colors, primary-main);
            }
            .dash-board-icon {
              background-position: -1px -79px;
            }
          }
        }
        .notification-sec {
          @include breakpoints(small) {
            text-align: center;
            width: auto;
            // padding: 15px 0;
            // margin: 15px 0;
            // border-top: 1px solid map-get($colors , doc-box-border);
            // border-bottom: 1px solid map-get($colors , doc-box-border);
            margin-left: 25px;
            margin-right: 25px;
          }
          .notify-icon {
            @include sprite-header;
            background-position: -133px -79px;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
            margin: 0 auto;
            padding-bottom: 35px;
            width: 22px;
            height: 27px;
            position: relative;
            .notification-circle {
              position: absolute;
              top: 0;
              right: -5px;
              width: 18px;
              height: 18px;
              padding-top: 3px;
              background-color: map-get($colors, error);
              color: #fff;
              text-align: center;
              font-size: 11px;
              font-weight: 500;
              @include border-radius(100%);
            }
            @include breakpoints(small) {
              padding-bottom: 0;
            }
          }
          &.active {
            a {
              color: map-get($colors, primary-main);
            }
            .notify-icon {
              background-position: -133px -32px;
            }
          }
          &:hover {
            a {
              color: map-get($colors, primary-main);
            }
            .notify-icon {
              background-position: -133px -32px;
            }
          }
        }
        .account-sec {
          position: relative;
          .home-menu {
            @include home-menu;
            top: 55px;
            right: -28px;
            @include breakpoints(large) {
              right: -4px;
            }
            @include breakpoints(small) {
              top: 35px;
              // right: 0;
              // left: -120px;
              // margin: 0 auto;
              // text-align: center;
              // width: 330px;
              // top: 0;
              // position: relative;
            }
            .home-menu-inner {
              @include home-menu-inner;
              width: 230px;
              &:before {
                @include breakpoints(large) {
                  right: 5px;
                }
                @include breakpoints(small) {
                  // right: 122px;
                }
              }
              @include breakpoints(small) {
                // width: 100%;
                // margin: 0 auto;
                // text-align: left;
              }
              a {
                @extend %myaccount-menu-font;
                display: block;
                position: relative;
                padding: 0 0 15px 30px;
                margin-bottom: 15px;
                border-bottom: 1px solid map-get($colors, silver-main);
                &:hover {
                  color: map-get($colors, primary-main);
                  &:before {
                    background-position: -53px -99.5px;
                  }
                  &:nth-child(2) {
                    &:before {
                      background-position: -3px -99.5px;
                    }
                  }
                  &:nth-child(3) {
                    &:before {
                      background-position: -6px -383px;
                    }
                  }
                }
                &:before {
                  @include sprite;
                  background-size: 415px;
                  background-position: -53px -145px;
                  width: 23px;
                  height: 24px;
                }
                &:nth-child(1) {
                  &:before {
                    // top: 4px;
                    @include breakpoints(large) {
                      // top: -2px;
                    }
                    @include breakpoints(small) {
                      // top: -4px;
                    }
                  }
                }
                &:nth-child(2) {
                  &:before {
                    top: 0;
                    background-position: -3px -145px;
                    @include breakpoints(large) {
                      // top: -4px;
                    }
                    @include breakpoints(small) {
                      // top: -8px;
                    }
                  }
                }
                &:nth-child(3) {
                  margin-bottom: 0;
                  border: 0;
                  &:before {
                    // top: 2px;
                    background-position: -6px -351px;
                    background-size: 550px;
                    @include breakpoints(large) {
                      // top: -3px;
                    }
                    @include breakpoints(small) {
                      // top: -7px;
                    }
                  }
                }
              }
            }
          }
          @include breakpoints(small) {
            width: auto;
            text-align: center;
            margin-top: 10px;
          }
          .myacc-icon {
            @include sprite-header;
            background-position: -72px -77px;
            margin: 0 auto;
            padding-bottom: 35px;
            width: 25px;
            height: 27px;
            @include breakpoints(small) {
              padding-bottom: 0;
            }
          }
          &.active {
            span {
              color: map-get($colors, primary-main);
              &:after {
                background-position: -132px -331px;
              }
            }
            .myacc-icon {
              background-position: -72px -30px;
            }
          }
          &:hover {
            .home-menu {
              display: block;
            }
            span {
              color: map-get($colors, primary-main);
              &:after {
                background-position: -132px -331px;
              }
            }
            .myacc-icon {
              background-position: -72px -30px;
            }
          }
          .menu-arrow-icon {
            padding-right: 15px;
            color: map-get($colors, grey-dark);
            cursor: pointer;
            position: relative;
            &:after {
              @include sprite;
              background-position: -132px -310px;
              top: auto;
              bottom: 4px;
              right: 0;
              left: auto;
              width: 13px;
              height: 8px;
              @include breakpoints(small) {
                bottom: 2px;
                right: -1px;
                display: none;
              }
            }
          }
          @supports (-moz-appearance: none) {
            .menu-arrow-icon {
              &:after {
                bottom: -46px;
                right: -96px;
                @include breakpoints(small) {
                  bottom: -45px;
                  right: -44px;
                }
              }
            }
          }
        }
      }
    }
  }
  .header-search {
    @include breakpoints(small) {
      position: relative;
      width: 100%;
      margin-top: 15px;
      margin-right: 0;
      .input-search {
        margin-bottom: 15px;
      }
      .search-btn {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(#{$imagePath}search-icon.png);
        width: 21px;
        height: 20px;
        border: 0;
        color: transparent;
        background-color: transparent;
        background-size: cover;
        @include breakpoints(medium) {
          width: 17px;
          height: 16px;
          right: 10px;
        }
      }
    }
  }
}
.menu-name {
  @include breakpoints(small) {
    display: none;
  }
}
.overflowy-hide {
  overflow-y: hidden;
  // position: fixed;
}
.cookie-concent-wrapper {
  background-color: #eff2fc;
  padding: 30px;
  padding-left: 70px;
  padding-right: 70px;
  font-size: 15px;
  transition: all 1s;
  visibility: visible;
  &.hidden {
    width: 0px;
    visibility: hidden;
    padding: 0px;
    height: 0;
  }
  & .next-prev-btn-pro {
    text-align: right;
    & .next-btn-pro {
      padding-left: 20px;
      padding-right: 20px;
      &:hover {
        color: #fff;
      }
    }
    @include breakpoints(small) {
      text-align: center;
    }
  }
  @include breakpoints(medium) {
    padding: 30px;
  }
}

.header-right--wrapper {
  display: flex;
  align-items: center;
  @include breakpoints(small) {
    display: none;
  }
}
.header-input {
  height: 68px;
  display: flex;
  gap: 8px;
  border-radius: 4px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  width: 30%;
  cursor: pointer;
  box-shadow: 0 2px 7px 0 #cedae1;
  -webkit-box-shadow: 0 2px 7px 0 #cedae1;
  border: solid 1px #dfdfdf;
  background-color: #ffffff;

  @include breakpoints(small) {
    width: 60%;
  }

  .first-span {
    color: #077db4;
  }

  .second-span {
    color: #ababab;
  }
}
.search-block {
  a {
    &::after {
      bottom: 12px !important;
    }
  }
}

.burger-menu {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  padding: 10px;
  z-index: 1100;

  .bar {
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
  }

  .bar-top,
  .bar-middle {
    width: 30px; /* Adjust the width as needed */
  }

  .bar-bottom {
    width: 22px; /* Adjust the width as needed */
  }

  /* Rotate bars into X shape when menu is visible */
  &.menu-visible {
    .bar-top {
      transform: rotate(-45deg) translate(-5px, 6px);
    }

    .bar-middle {
      opacity: 0;
    }

    .bar-bottom {
      transform: rotate(45deg) translate(-6px, -7.5px);
      width: 30px;
    }
  }
}

.mobile-menu {
  display: none;
}

/* Media query for mobile */
@media (max-width: 767px) {
  .burger-menu {
    display: flex; /* Show on smaller screens */
  }

  .mobile-menu {
    padding-top: 100px;
    padding-right: 30px;
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* Add your desired background color */
    transform: translateX(100%); /* Initially off-screen to the right */
    transition: transform 0.3s ease-in-out;
    z-index: 1000; /* Ensure the menu is above the overlay */
    &.mobile-menu--visible {
      transform: translateX(0%); /* Initially off-screen to the right */
      transition: transform 0.3s ease-in-out;
    }
    .header-practice-link {
      width: auto;
      border: none;
      font-size: 16px;
      padding-right: 0;
    }
    .create-acc-block {
      a {
        width: 151px;
      }
      width: 100%;
      align-items: flex-end;
      font-size: 16px;
      padding-right: 14px;
      display: flex;
      flex-direction: column;
      .home-menu--mobile {
        width: 100%;
      }
      .home-menu-links {
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 40px);
        border-bottom: 1px solid #d4d4d4;
        margin: 20px 0 20px 40px;
        span {
          color: #000;
          font-weight: 700;
          a {
            color: #666;
            border-bottom: 2px dotted #333333;
          }
        }
      }
    }

    .sign-in-block {
      .sign-in-href {
        width: 151px;
      }
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      margin-top: 8px;
      font-size: 16px;
      .home-menu--mobile {
        width: 100%;
      }
      .home-menu-links {
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 40px);
        border-bottom: 1px solid #d4d4d4;
        margin: 20px 0 20px 40px;
        span {
          color: #000;
          font-weight: 700;
          a {
            color: #666;
            border-bottom: 2px dotted #333333;
          }
        }
      }
    }
  }
}

.mobile-menu {
  .sign-in-block {
    .sign-in-href {
      &::after {
        top: 11px;
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.modal-content {
  width: 100vw;
  height: 100vh;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.modal-headerr {
  border: none;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
