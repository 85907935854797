.input {
  border: 1px solid #077db4;
  color: #077db4;
  font-weight: bold;
  padding: 10px 44px;
  border-radius: 50px;
  width: 190px;
  outline: none;
  font-size: 14px;
}

.chip-input-container {
  display: flex;
  align-items: center; /* Align items vertically */
}

.icon {
  margin-right: -40px;
  z-index: 10;
}

.Xicon {
  margin-left: -40px;
  z-index: 10;
  cursor: pointer;
  width: '24px';
}
