.search-modal-wrap {
  .edit-all-filter {
    max-width: 800px;
    margin: 0 auto;
    background-color: map-get($colors, white1);
    @include breakpoints(medium) {
      width: 100%;
    }
  }
  .modal-head {
    @include modal-head;
    @include breakpoints(small) {
      padding: 10px 20px;
      margin-bottom: 15px;
    }
  }
  .allfilter-innerbox {
    padding: 0 36px;
    margin: 0 auto;
    @include breakpoints(medium) {
      padding: 0;
    }
  }
  .virtual-quick-wrap {
    @include wrap-flx;
    justify-content: space-between;
    .virtual-left {
      width: 41%;
      @include breakpoints(small) {
        width: 100%;
        margin-bottom: 20px;
      }
      .virtual-inner {
        @include wrap-flx;
        justify-content: space-between;
        margin-top: 10px;
        @include breakpoints(medium) {
          span {
            width: 100%;
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }

        .virtual-icon {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
    .quick-right {
      display: flex;
      align-items: flex-end;
      width: 50%;
      @include breakpoints(small) {
        width: 100%;
      }
      .quick-inner {
        @include wrap-flx;
        justify-content: space-between;
        gap: 40px;
        @include breakpoints(medium) {
          margin-bottom: 15px;
          span {
            width: 100%;
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
        .video-icon {
          &:before {
            background-position: -39px -228px;
            width: 26px;
            height: 26px;
            background-size: 340px;
          }
        }
      }
    }
  }
  .modal-filterby {
    @include wrap-flx;
    justify-content: space-between;
    .filterby-outer {
      margin-top: 10px;
      &:nth-child(1) {
        width: 29%;
        @include breakpoints(small) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        border-left: 1px solid map-get($colors, doc-box-border);
        border-right: 1px solid map-get($colors, doc-box-border);
        padding: 0 13px;
        width: 30%;
        @include breakpoints(small) {
          width: 100%;
          padding: 0;
          border: 0;
        }
      }
      &:nth-child(3) {
        width: 38%;
        @include breakpoints(small) {
          width: 100%;
        }
      }
    }
  }
  .video-icon {
    padding-left: 35px;
    line-height: 1.8;
    font-size: 14px;
    &:before {
      top: 0;
    }
  }
  .switch {
    margin-left: 32px;
    .slider {
      &:after {
        content: 'Off';
      }
    }
    input:checked + .slider:after {
      content: 'On';
    }
  }
  .icons-inner {
    @include wrap-flx;
    justify-content: space-between;
    gap: 4px;
    font-size: 14px;
    @include breakpoints(medium) {
      span {
        width: 100%;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
    @include breakpoints(small) {
      span {
        width: auto;
      }
    }
    .patients-icon {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .info-filter {
    padding: 0;
    cursor: pointer;
  }
  .tick-icon {
    padding-left: 30px;
  }
  .gender-allfilter {
    @include wrap-flx;
    gap: 80px;
    @include breakpoints(small) {
      gap: 40px;
    }
    .cus-radio {
      > label {
        font-size: 16px;
        @include breakpoints(small) {
          font-size: 14px;
        }
        &:before {
          width: 29px;
          height: 29px;
          top: 9px;
        }
      }
      > input:checked + label:after {
        width: 15px;
        height: 15px;
        top: 16px;
        left: 7px;
      }
      > input:checked + label:before {
        border-color: map-get($colors, primary-main);
      }
    }
  }
  .filter-speciality {
    margin-top: 10px;
    @include wrap-flx;
    justify-content: space-between;
    @extend %scheduling-page-font;
    .cus-check-rev {
      width: 27%;
      margin-bottom: 23px;
      @include breakpoints(medium) {
        width: 45%;
      }
      @include breakpoints(small) {
        width: 100%;
      }
    }
  }
  .filter-language {
    margin-top: 10px;
    @include wrap-flx;
    justify-content: space-between;
    @extend %scheduling-page-font;
    .cus-check-rev {
      width: 22%;
      margin-bottom: 20px;
      margin-bottom: 23px;
      @include breakpoints(medium) {
        width: 30%;
      }
      @include breakpoints(small) {
        width: 44%;
      }
    }
  }
  .all-fltrbtn {
    text-align: center;
    .next-btn-pro {
      max-width: 375px;
      margin: 10px 0;
      @include breakpoints(small) {
        max-width: 100%;
      }
    }
  }
  .myform {
    &.req-appoint {
      font-size: 14px;
    }
  }
  .pro-label {
    span {
      color: map-get($colors, delete-color);
    }
  }
  .textarea-description {
    .input-pro {
      height: 90px;
    }
    .textarea-note {
      @extend %scheduling-page-font;
      color: map-get($colors, filter-border);
      letter-spacing: 0.58px;
    }
  }
  .seaprator-title {
    @extend %hg-label-font;
    color: map-get($colors, grey-dark);
    max-width: 75%;
    span {
      .lock {
        top: -2px;
        margin-right: 5px;
      }
    }
  }
  .demo-medical-wrap {
    @include wrap-flx;
    justify-content: space-between;
    .demo-left,
    .medical-right {
      width: 45%;
      @include breakpoints(small) {
        width: 100%;
      }
      .head {
        @extend %demo-medical-head-font;
        margin-bottom: 15px;
      }
      .demo-medical-inner {
        @include wrap-flx;
        justify-content: space-between;
        .dmleft,
        .dmright {
          width: 48%;
          line-height: 2;
          @include breakpoints(medium) {
            font-size: 14px;
          }
        }
        .dmleft {
          @include medium-font;
          @include breakpoints(medium) {
            font-size: 14px;
          }
        }
      }
    }
    .demo-left {
      @include breakpoints(small) {
        margin-bottom: 20px;
      }
    }
  }
}
