.all-filter-wrap{
    margin-bottom: 20px;
    @include wrap-flx;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    @extend %all-filter-font;
    @include breakpoints(large){
        padding: 0;
    }
    .filter-tab{
        @include filter-tab;
        margin-left: 0;
        width: 16%;
        @include breakpoints(large){
            width: 20%;
        }
        @include breakpoints(medium){
            width: 25%;
        }
        @include breakpoints(small){
            width: 100%;
            text-align: left;
            margin: 5px 0 25px;
        }
    }
    .visit-available{
        border-right: 1px solid map-get($colors, ford-gray);
        width: 25.5%;
        padding-right: 35px;
        @include breakpoints(large){
            width: 25%;
            text-align: center;
        }
        @include breakpoints(medium){
            padding-right: 0px;
            width: 23%;
        }
        @include breakpoints(small){
            width: 100%;
            margin-bottom: 20px;
            border: 0;
        }
        .visit-inner{
            @include wrap-flx;
            justify-content: space-between;
            span{
                &:first-child{
                    .switch{
                        .slider{
                            &:after{
                                content:'Off';
                            }
                        }
                        input:checked + .slider:after{  
                            content:'On';
                        }                
                    }
                    @include breakpoints(large){
                        width: 100%;
                        margin:0 30px 5px 0;
                    }
                    @include breakpoints(small){
                        width: auto;
                        margin:0;
                        margin-left: 30px;
                    }
                }
            }            
            .video-icon{
                color: map-get($colors, filter-color);
                letter-spacing: 0.5px;
                &:before{
                    left: -35px;
                    top:0;
                    @include breakpoints(small){
                        left: 0;
                    } 
                }
                @include breakpoints(small){
                    padding-left: 35px;
                }                
            }
        }
    }
    .new-patient-search{
        border-right: 1px solid map-get($colors, ford-gray);
        width: 29%;
        padding:0 35px;
        @include breakpoints(large){
            width: 25%;
            text-align: center;
        }
        @include breakpoints(medium){
            padding-right: 0px;
            width: 27%;
        }
        @include breakpoints(small){
            width: 100%;
            margin-bottom: 20px;
            border: 0;
            padding: 0;
        }
        .new-pat-inner{
            @include wrap-flx;
            justify-content: space-between;
            span{
                &:first-child{
                    .switch{
                        .slider{
                            &:after{
                                content:'Off';
                            }
                        }
                        input:checked + .slider:after{  
                            content:'On';
                        }                
                    }
                    @include breakpoints(large){
                        width: 100%;
                        margin:0 30px 5px 0;
                    }
                    @include breakpoints(small){
                        width: auto;
                        margin:0;
                        margin-left: 30px;
                    }
                }
            }
            .tick-icon{
                letter-spacing: 0.5px;
                &:before{
                    left: -30px;
                    top:2px;
                    @include breakpoints(small){
                        left: 0;
                    } 
                }
                @include breakpoints(small){
                    padding-left: 30px;
                }
            }
        }
    }
    .search-top-doc{
        width: 18%;
        padding-left: 30px;
        @include breakpoints(large){
            width: 20%;
        }
        @include breakpoints(medium){
            width: 15%;
            padding-left: 20px;
        }
        @include breakpoints(small){
            width: 100%;
            border: 0;
            padding: 0;
        }
        .search-td-inner{
            @include wrap-flx;
            justify-content: space-between;
            span{
                &:first-child{
                    .switch{
                        .slider{
                            &:after{
                                content:'Off';
                            }
                        }
                        input:checked + .slider:after{  
                            content:'On';
                        }                
                    }
                    @include breakpoints(medium){
                        width: 100%;
                        margin:0 0 5px 15px;
                    }
                    @include breakpoints(small){
                        width: auto;
                        margin:0;
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}
