.patient-appointment-wrap {
  .appoint-sub-head-wrap {
    @include wrap-flx;
    justify-content: space-between;
    margin-bottom: 0;
    h2 {
      font-weight: 500;
      letter-spacing: 0.5px;
      letter-spacing: 1px;
      @include breakpoints(small) {
        width: 55%;
      }
    }
    &.prev-head {
      h2 {
        width: 100%;
      }
    }
    .filter-tab {
      @include filter-tab;
      @include breakpoints(small) {
        margin-top: 10px;
      }
    }
  }
  .appoint-req-record {
    @include medium-font;
    letter-spacing: 0.3px;
    .time-icon {
      margin-right: 7px;
      position: relative;
    }
    span {
      position: relative;
      &:last-child {
        @include bold-font;
        letter-spacing: 0.3px;
        margin-left: 15px;
        padding-left: 15px;
      }
      &:before {
        content: '|';
        position: absolute;
        left: 0;
        top: -4px;
      }
    }
  }
  .schedule-icons {
    button {
      background-color: #fff;
      position: relative;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      font-weight: bold;
      &:first-child {
        padding: 6px 10px 6px 40px;
        justify-content: flex-end;
        margin-right: 6px;
        &:before {
          @include schedule-icons;
          top: 5px;
          left: 8px;
          background-position: -297px -412px;
        }
      }
      &:last-child {
        padding: 6px 10px 6px 40px;
        justify-content: flex-end;
        margin-right: 6px;
        &:before {
          @include schedule-icons;
          transform: scale(0.8);
          background-position: -447px -77px;
          top: 5px;
          left: 8px;
        }
      }
    }
  }

  .cancel-schedule-icons {
    width: 100% !important;
    span {
      position: relative;
      padding-left: 32px;
      font-weight: bold;
      font-size: 16px;
      &:before {
        @include schedule-icons;
        transform: scale(0.8);
        background-position: -447px -77px;
        top: -4px;
        left: 0px;
      }
    }
  }
  .schedule-icons {
    text-align: right;
    @include medium-font;
    letter-spacing: 0.3px;
    @include breakpoints(small) {
      text-align: left;
    }
    span {
      position: relative;
      &:first-child {
        margin-right: 30px;
        padding-left: 35px;
        &:before {
          @include schedule-icons;
          top: -4px;
        }
      }
      &:last-child {
        padding-left: 35px;
        &:before {
          @include schedule-icons;
          top: -4px;
          background-position: 0 -80px;
        }
      }
    }
  }
  .appoint-box {
    margin-top: 20px;
    @include box-shadow-style;
    @include border-radius(10px);
    padding-bottom: 20px;
    @include breakpoints(medium) {
      padding: 15px 0;
      max-width: 100%;
      overflow-x: scroll;
    }
    @include breakpoints(small) {
      padding: 0 0 10px;
    }
    .appoint-box-content {
      max-height: 800px;
      overflow-y: auto;
      margin: 10px 10px 0 0;
      padding-right: 50px;
      padding-left: 50px;
      @include breakpoints(medium) {
        width: 1050px;
      }
      @include breakpoints(small) {
        width: 980px;
      }
      &::-webkit-scrollbar {
        width: 18px;
        @include breakpoints(medium) {
          width: 0px;
        }
      }
      &::-webkit-scrollbar-track {
        background-color: #e3e3e3;
        padding: 0 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        cursor: pointer;
        width: 10px;
        border: 4px solid #e3e3e3;
        @include breakpoints(medium) {
          width: 6px;
          border: 3px solid #e3e3e3;
          visibility: hidden;
        }
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #077db4;
      }
      .table-heading-wrap-device {
        @include appointment-table-width;

        display: none;
        div {
          width: 20%;
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 22.5%;
          }
          &:nth-child(4) {
            width: 21%;
          }
        }
        @include breakpoints(medium) {
          display: flex;
          div {
            width: 54px;
            &:nth-child(2) {
              width: 225px;
            }
            &:nth-child(3) {
              width: 202px;
            }
            &:nth-child(4) {
              width: 151px;
            }
          }
        }
      }
    }
    .table-heading-wrap {
      @include appointment-table-width;
      padding-left: 50px;
      padding-right: 78px;
      @include breakpoints(medium) {
        display: none;
      }
      div {
        width: 10%;
      }
    }
    .table-content-appointment {
      img {
        &.profile-pic {
          height: 120px;
          width: 120px;
          border-radius: 50%;
        }
      }
      @include appointment-table-width;
      padding-left: 0;
      padding-right: 0;
      border-width: 1px;
      div {
        width: 10%;
        @extend %body;
        &:nth-child(2) {
          width: 25%;
        }
        &:nth-child(3) {
          width: 22.5%;
        }
        &:nth-child(4) {
          width: 21%;
        }
        p {
          padding: 0;
          margin: 0;
          span {
            &:first-child {
              @extend %body;
              font-weight: normal;
              margin-right: 3px;
              padding-right: 15px;
              display: block;
            }
            &.dr-name {
              @extend %covid-screen-box-font-1;
              color: map-get($colors, primary-main);
              font-weight: bold;
            }
            &.req-by {
              @extend %notification-heading-font;
              font-weight: bold;
            }
          }
        }
        &:last-child {
          @extend %body;
          font-weight: normal;
          .schedule-status {
            li {
              position: relative;
              padding-left: 35px;
              margin-bottom: 10px;
              &.blank {
                &:before {
                  @include schedule-icons;
                  top: -2px;
                  background-position: 0 -43px;
                }
              }
              &.scheduled {
                &:before {
                  @include schedule-icons;
                  top: -2px;
                  background-position: 0 0px;
                }
              }
              &.unscheduled {
                &:before {
                  @include schedule-icons;
                  top: -2px;
                  background-position: 0 -80px;
                }
              }
              &.cancelled {
                &:before {
                  @include schedule-icons;
                  top: -2px;
                  background-position: 0 -120px;
                }
              }
            }
            .switch-link {
              font-weight: 500;
              a {
                color: map-get($colors, primary-main);
              }
            }
          }
        }
        &.profile-review-box {
          border: 0;
          box-shadow: unset;
          padding: 0;
          .rating-result {
            width: 100%;
            span {
              &:nth-child(2) {
                display: flex;
                @extend %body;
              }
            }

            .rating {
              height: 29px;
              width: 175px;
              background-size: 35px;

              span {
                height: 30px;
                background-size: 35px;
                background-position: 0 -31px;
              }
            }
          }
          .write-review {
            width: 220px;
            position: unset;
            background-color: map-get($colors, primary-main);
            padding: 14px 40px 14px 55px;
            a {
              padding: 0px;
            }
            span {
              color: map-get($colors, white1);
              &:before {
                content: '\2605';
                background-image: none;
                font-size: 25px;
                top: -10px;
                left: -30px;
              }
            }
          }
        }
        .doctor-status {
          .blank {
            @extend %notification-heading-font;
            font-weight: 500;
            color: map-get($colors, primary-main);
            cursor: pointer;
            img {
              margin: 2px 10px 0 0;
            }
          }
        }
        & .stars-outer {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  .prev-file-wrap {
    @include box-shadow-style;
    @include border-radius(10px);
    border: 0;
    padding: 50px 0 0px;
    margin-bottom: 50px;
    @include breakpoints(small) {
      margin-bottom: 30px;
      padding: 20px 0 0;
    }
    .prev-file-box {
      padding: 0 30px 40px;
      border-bottom: 1px solid map-get($colors, profile-border);
      margin-bottom: 40px;
      @include medium-font;
      @include breakpoints(small) {
        margin-bottom: 20px;
        padding: 0 10px 20px;
      }
      &:last-child {
        margin-bottom: 0;
        border: 0;
      }
      a {
        border: 2px solid map-get($colors, primary-main);
        color: map-get($colors, primary-main);
        padding: 13px 35px 13px 40px;
        .xls-icon {
          position: relative;
          top: -3px;
          padding-right: 10px;
        }
        &:hover {
          background-color: map-get($colors, link-hover);
        }
      }
    }
  }
}

.popup-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 14px 0 #e4eef3;
  background-color: #ffffff;
  border-radius: 10px;
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 65px;
    .popup-buttons {
      width: 85%;
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      button {
        width: 48%;
      }
    }
  }
}

.reschedule {
  display: flex;
}

.popup-confirm {
  color: #077db4;
  border: 1px solid #077db4;
  border-radius: 4px;
  padding: 12px;
  background-color: #fff;
}

.popup-cancel {
  background-color: #077db4;
  border: none;
  color: #ffffff;
  border-radius: 4px;
  padding: 12px;
}
