.speciality-wrap{ 
    .table-report-scroll{
        margin-top: 0 !important;
    }
    .comment-medical{
        width: 550px;
        white-space: normal;
        @include breakpoints(large){
            width: 450px;
        }
        @include breakpoints(small){
            width: 180px;
        }
    }
    .comment-school{
        width: 85%;
        white-space: normal;
    }
    .speciality-filter-top{
        .speciality-filter{            
            .speciality-links{
                width: 100%;
                margin-top: 5px;
            }
        }
    }    
    .speciality-only-link{
        .speciality-filter{
            .filter-input{
                display: none;
            }
            margin-bottom: 15px;
            .speciality-links{
                width: 100%;
            }
        }
    }
}
.speciality-modal-wrap{
    width: 680px;
    @include breakpoints(medium){
        width: 100%;
    }
    .all-fltrbtn{
        .del-btn-pro{
            max-width: 375px;
            margin: 0 auto;
        }
        .del-btn-pro:hover{
            background: #d93025;
            color: #fff;
        }
    }
}
.table-action {
    background-color: transparent;
    border: none
}