.care-team-wrap {
  h2 {
    font-weight: 500;
    margin-bottom: 15px;
    letter-spacing: 1px;
  }
  .subhead {
    @extend %care-team-subhead-font;
    margin-bottom: 15px;
  }
  .ct-outer-box {
    @include box-shadow-style;
    @include border-radius(10px);
    padding: 20px 10px 20px 30px;
    margin-bottom: 40px;
    @include breakpoints(small) {
      padding: 10px 5px 10px 10px;
    }
    &:last-child {
      margin-bottom: 60px;
    }
  }
  .ct-inner-box {
    overflow-y: auto;
    @extend %scrollbarDesign1;
    max-height: 620px;
  }
  .care-team-list {
    .scheduling-box {
      display: block;
    }
    margin: 10px 8px 0 0;
    .col-12 {
      padding: 0 !important;
    }

    .address-left {
      margin-right: 15px;
      width: 265px;
    }
  }
  .endorse-link {
    display: none;
  }
  .endorse-link-patient {
    display: block;
    a {
      color: map-get($colors, primary-main);
      border-color: map-get($colors, primary-main);
    }
  }
  .patient-features {
    display: none;
  }

  .scheduling-box {
    padding: 0 0px 0px 0;
    @include breakpoints(large) {
      padding-right: 10px;
    }
    @include breakpoints(medium) {
      padding-right: 0;
    }
    .date-area-patient {
      .date-column {
        .date-heading {
          margin-bottom: 8px;
          span {
            font-size: 15px;
          }
        }
        @include breakpoints(large) {
          width: 17.5%;
        }
        .date-list {
          padding: 15px 0;
          font-size: 13px;
          font-weight: 500;
          @include breakpoints(small) {
            padding: 5px;
          }
        }
      }
      img.left-arrow {
        left: -25px;
        @include breakpoints(medium) {
          left: 0;
        }
        @include breakpoints(small) {
          left: -8px;
        }
      }
      img.right-arrow {
        right: -25px;
        @include breakpoints(medium) {
          right: 0;
        }
        @include breakpoints(small) {
          right: -8px;
        }
      }
    }
  }
  .doctor-description-wrap {
    padding: 50px 0 30px;
    margin-top: 20px;
    @include breakpoints(small) {
      padding: 0;
      margin-top: 10px;
    }
  }
  .scheduling-features {
    display: block;
  }
  .top-doctor-list,
  .other-doctor-list {
    .scheduling-box {
      display: none;
    }
    .endorse-link-patient {
      a {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .doc-save {
    display: block;
  }
  .saved-doctors-box {
    .doc-save {
      display: block;
      cursor: pointer;
    }
    .doc-unsave {
      display: block;
      cursor: pointer;
    }
  }

  .recommended-doctors-box {
    .doc-save {
      display: block;
      cursor: pointer;
      line-height: 1.2;
      letter-spacing: 1px;
    }
    .doc-unsave {
      display: block;
      cursor: pointer;
      line-height: 1.2;
      letter-spacing: 1px;
    }
  }

  @include breakpoints(small) {
    .doc-save,
    .doc-unsave {
      top: -25px;
      left: -12px;
      font-size: 14px;
      .icon-docsave {
        width: 14px;
        margin: 0 5px 0 0;
        top: 0px;
      }
    }
  }

  .scheduling-component-main {
    margin-right: 0;
    .date-area-months {
      overflow: visible;
      padding-bottom: 5px;
      .left-arrow {
        left: -15px;
      }
      .right-arrow {
        right: -15px;
      }
    }
  }
}
