.provider-profile-inner {
  @include wrap-flx;
  justify-content: space-between;
}
.provider-detail-left-top,
.provider-detail-right-top {
  width: 48.5%;
  box-sizing: border-box;
  padding: 30px;
  @include breakpoints(medium) {
    width: 100%;
  }
  @include breakpoints(medium) {
    padding: 30px 15px;
  }
  &.pro-profile-box-style {
    @include box-shadow-style;
  }
  &.top-doc-border {
    @include top-doc-border;
  }
  .top-doc-icon {
    @include top-doc-icon;
    left: 25px;
  }
  .provider-detail-box {
    text-align: center;
    position: relative;
    width: 100%;
    .provider-doc-img {
      width: 180px;
      height: 180px;
      margin: 0 auto;
      overflow: hidden;
      margin-bottom: 25px;
      @include border-radius(100%);
      img {
        width: 180px;
        height: 180px;
      }
    }
    h5 {
      color: map-get($colors, primary-main);
      letter-spacing: 0.8px;
      span {
        position: relative;
        padding: 0 0 0 35px;
        font-size: 14px;
        font-weight: 500;
        color: map-get($colors, black1);
        letter-spacing: 0.5px;
        &:before {
          @include sprite;
          background-size: 520px;
          background-position: -280px -181px;
          width: 24px;
          height: 24px;
          left: 6px;
          top: -7px;
        }
      }
    }
    .provider-designation {
      font-size: 16px;
      font-weight: 700;
      color: map-get($colors, black1);
      letter-spacing: 0.6px;
      margin: 5px 0;
    }
    .endorsements,
    .rating-result {
      @include endorsements;
    }
    .rating-result {
      padding: 0;
    }
    @include breakpoints(small) {
      .rating-result {
        padding-left: 0;
        span {
          &:last-child {
            display: block;
          }
        }
      }
    }
    .endorsements {
      @include endorsements-icon;
    }
  }
  .profile-eligible-icons {
    @include wrap-flx;
    @include reg-font;
    justify-content: space-between;
    margin: 25px 0 35px;
    padding: 35px 0;
    border-top: 2px solid map-get($colors, profile-border);
    border-bottom: 2px solid map-get($colors, profile-border);
    @include breakpoints(small) {
      padding: 10px 0 0;
      margin: 25px 0;
    }
    span {
      position: relative;
      padding: 0 0 0 35px;
      @include breakpoints(small) {
        padding: 0 0 0 35px;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  .edu-lang-wrap {
    .head-edu {
      font-size: 20px;
      font-weight: 700;
      color: map-get($colors, black1);
      letter-spacing: 0.8px;
      position: relative;
      @include breakpoints(small) {
        font-size: 18px;
      }
    }
    ul {
      margin-top: 25px;
      @include breakpoints(small) {
        margin-top: 10px;
      }
      li {
        font-size: 16px;
        letter-spacing: 0.7px;
        color: map-get($colors, black1);
        line-height: 1.8;
        position: relative;
        padding: 0 0 0 50px;
        margin-bottom: 20px;
        @include breakpoints(small) {
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 10px;
        }
        > span {
          font-size: 18px;
          font-weight: 900;
          display: block;
          position: relative;
        }
      }
    }
  }
  .endorse-link {
    margin-top: 30px;
    @include breakpoints(small) {
      margin-top: 20px;
    }
  }
  .reviews-wrapper {
    .reviews {
      margin-top: 14px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .review {
        margin-bottom: 40px;
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:nth-child(2),
        &:nth-child(5) {
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }

        span {
          color: #077db4;
          font-weight: 500;
        }
        h5 {
          margin-bottom: 10px;
          opacity: 0.7;
        }
        p {
          color: #606060;
          opacity: 0.8;
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }

  .doc-profile-switcher-wrap {
    margin: 25px 0 35px;
    padding: 35px 0;
    border-top: 2px solid map-get($colors, profile-border);
    border-bottom: 2px solid map-get($colors, profile-border);
    .switcher-inner {
      @include wrap-flx;
      @include medium-font;
      justify-content: space-between;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      @include breakpoints(large) {
        span {
          &:first-child {
            width: 80%;
          }
        }
      }
      @include breakpoints(small) {
        span {
          &:first-child {
            width: 75%;
          }
        }
      }
    }
    @include breakpoints(small) {
      padding: 20px 0;
      margin: 25px 0;
    }
  }
  .edit-doc-profile,
  .edit-medical,
  .edit-lang,
  .edit-edu {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 3px;
    display: none;
  }
  .edit-edu {
    padding: 0;
    color: map-get($colors, primary-main);
    font-weight: 500;
  }
  .edit-doc-profile {
    right: 0;
    top: -55px;
    @include breakpoints(small) {
      top: -25px;
    }
  }
}
.add-icon {
  position: relative;
  top: 5px;
  padding-right: 4px;
  @include breakpoints(small) {
    top: 2px;
  }
}
